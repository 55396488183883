// src/slices/orderSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
  orderId: string | null;
  selectedQuantity: number;
  geoCoordinates: {
    lat: number | null;
    long: number | null;
  };
  selectedBundleId: string | null;
  bundlePlanss: number | null;
  packageList: number[];
  selectedPackage: number | null;
  currencySymbol: string;
  planPrice: number;
  orderQuantity: number;
  countryNam: string;
  flag: string;
  coverageCount: number;
}

const initialState: OrderState = {
  orderId: null,
  selectedQuantity: 1,
  geoCoordinates: {
    lat: null,
    long: null,
  },
  selectedBundleId: null,
  bundlePlanss: null,
  packageList: [],
  selectedPackage: null,
  currencySymbol: "$",
  planPrice: 0,
  orderQuantity: 1,
  countryNam: "",
  flag: "",
  coverageCount: 0,
};

const orderSlice = createSlice({
  name: 'orderDetail',
  initialState,
  reducers: {
    setSelectedQuantity: (state, action: PayloadAction<number>) => {
      state.selectedQuantity = action.payload;
    },
    setGeoCoordinates: (state, action: PayloadAction<{ lat: number; long: number }>) => {
      state.geoCoordinates = action.payload;
    },
    setSelectedBundleId: (state, action: PayloadAction<string>) => {
      state.selectedBundleId = action.payload;
    },
    setBundlePlanss: (state, action: PayloadAction<number>) => {
      state.bundlePlanss = action.payload;
    },
    setPackageList: (state, action: PayloadAction<number[]>) => {
      state.packageList = action.payload;
    },
    setSelectedPackage: (state, action: PayloadAction<number>) => {
      state.selectedPackage = action.payload;
    },
    setCurrencySymbol: (state, action: PayloadAction<string>) => {
      state.currencySymbol = action.payload;
    },
    setPlanPrice: (state, action: PayloadAction<number>) => {
      state.planPrice = action.payload;
    },
    setOrderQuantity: (state, action: PayloadAction<number>) => {
      state.orderQuantity = action.payload;
    },
    setCountryNam: (state, action: PayloadAction<string>) => {
      state.countryNam = action.payload;
    },
    setFlag: (state, action: PayloadAction<string>) => {
      state.flag = action.payload;
    },
    setCoverageCount: (state, action: PayloadAction<number>) => {
      state.coverageCount = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload; // Just store it in Redux, no localStorage
    },
  },
});

export const {
  setSelectedQuantity,
  setGeoCoordinates,
  setSelectedBundleId,
  setBundlePlanss,
  setPackageList,
  setSelectedPackage,
  setCurrencySymbol,
  setPlanPrice,
  setOrderQuantity,
  setCountryNam,
  setFlag,
  setCoverageCount,
  setOrderId,
} = orderSlice.actions;

export default orderSlice.reducer; // Export the reducer to be used in the store
