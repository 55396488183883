import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";
import {
    GetOrderDetailsResponse,
    AddOrderResponse,
    AddOrderPayload,
    UpdateOrderPayload,
    PaymentIntentPayload,
    PaymentIntentResponse,
    PromosResponse,
    WalletResponse

} from "../interface";

const getClientIP = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP:', error);
        return '';
    }
}
const addOrder = async (values: AddOrderPayload): Promise<AddOrderResponse> => {
    const clientIP = await getClientIP(); 
    return axios.post(`${API_URL}/order`, values, 
    {
        headers: {
            'X-Forwarded-For': clientIP, // Set the client IP in headers
        }
    }
    );
};

const getDocumentStatus = async (values?: any): Promise<any> => {
    const clientIP = await getClientIP(); 

    // if(!values?.latitude || !values?.longitude) {
    //     return axios.get(`${API_URL}/bundle/showDocuments`);
    // }
    // return axios.get(`${API_URL}/bundle/showDocuments?latitude=${values?.latitude}&longitude=${values?.longitude}`);

    return axios.get(`${API_URL}/bundle/showDocuments`,   {
        headers: {
            'X-Forwarded-For': clientIP, // Set the client IP in headers
        }
    }) ;
};

const getOrderDetails = async (id: string): Promise<GetOrderDetailsResponse> => {
    return axios.get(`${API_URL}/order/details/${id}`);
};

const updateOrder = async (
    values: UpdateOrderPayload
): Promise<GetOrderDetailsResponse> => {
    const clientIP = await getClientIP(); 

    return axios.put(`${API_URL}/order`, values,
     {
        headers: {
            'X-Forwarded-For': clientIP, // Set the client IP in headers
        }
    }
    );
};

const stripePaymentIntent = async (
    values: PaymentIntentPayload
): Promise<PaymentIntentResponse> => {
    return await axios.post(`${API_URL}/stripe/paymentIntent`, values);
};

const getPromos = async (): Promise<PromosResponse> => {
    return axios.get(`${API_URL}/promos`);
};

const walletAmtUpdate = async (values:any): Promise<WalletResponse> => {
    return axios.get(`${API_URL}/order/walletAmount`, values);
};

const razorpayAddOrder = async (values: any): Promise<any> => {
    return axios.post(`${API_URL}/razorpay/order`, values);
};
const purchaseOrder = async (values: any): Promise<any> => {
    return axios.post(`${API_URL}/order/purchaseZeroPriceOrder`, values);
};

const getOfferForYou = async (params:any): Promise<any> => {
    return axios.get(`${API_URL}/getOfferForYou`, {params});
};
const gapplyOfferForYou = async (params:any): Promise<any> => {
    return axios.post(`${API_URL}/applyOffer`, params);
};

const getDevicesName = async (params:any): Promise<any> => {
    return axios.get(`${API_URL}/esim/deviceList`, {params});
};

const getFilterData = async (params: any) : Promise<any>=> {
    return await axios.get(`${API_URL}/bundle/filter2`, { params });
}
const guestLogin = async (values:any): Promise<any> => {
    return axios.post(`${API_URL}/auth/guestSignup`, values);
}
    export async function reverseGeocode2(ip: any): Promise<any> {
        const url = `https://api.ipinfo.io/lite/${ip}?token=ed0651b68a4f39`;
        
        try {
        console.log("Checking URL:", url);
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        // Ensure the country_code exists and return it, otherwise return an empty string
        return data && data.country_code ? data.country_code.toUpperCase() : "";
        } catch (error) {
        console.error("Error during reverse geocoding:", error);
        return "";
        }
    }
    const getGuestIP = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            console.log(response,"akshaythakur")
            const data = await response.json();
            console.log(data,"akshaythakur")
            return data.ip;
        } catch (error) {
            console.error('Error fetching IP:', error);
            return '';
        }
    }
export {
    getGuestIP,
    guestLogin,
    getDevicesName,
    getOrderDetails,
    addOrder,
    updateOrder,
    stripePaymentIntent,
    getPromos,
    walletAmtUpdate,
    getDocumentStatus,
    razorpayAddOrder,
    purchaseOrder,
    getOfferForYou,
    gapplyOfferForYou,
    getFilterData
};
