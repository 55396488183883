export const STRINGS = {

    PAGE_TITLE: 'Commbitz | Global eSIM Solutions for Travel Connectivity',
    HOME_BANNER_TITLE: 'Get your Global eSIM activated in seconds.',
    HOME_BANNER_SUBTITLE: "We've got you covered!",
    INSTALLATION_GUIDE: 'Installation Guide',
    ANDROID_GUIDELINES: 'Guidelines for Android',
    IOS_GUIDELINES: 'Guidelines for iOS',
    CUSTOMER_SUPPORT: 'Customer Support',
    DEVICE_COMPATIBILITY: 'Check your device Compatibility',
    ENTER_PHONE_MODEL: 'Enter your Phone Model',
    ACTIVATE_ESIM: 'Activate your eSIM',
    ENTER_ESIM_NUMBER: 'Enter eSIM Number',
    APPLY_BUTTON: 'Apply',
    CONTACT_LINK: '/contact',
      // Review Section
  REVIEWS_TITLE: 'Reviews',
  REVIEWS_SUBTITLE: 'Explore Commbitz: See What Users Say',
  
  REVIEW_1_TEXT: 'I’ve been using the eSIM service for a few months now and I’m extremely satisfied with the performance. Switching to the eSIM was hassle-free, and I never have to worry about changing physical SIM cards when I travel. Connectivity is fast and reliable, even in remote areas. Highly recommend!',
  REVIEW_1_AUTHOR: 'John Wilson, Melbourne',
  REVIEW_1_PLATFORM: 'Google',

  REVIEW_2_TEXT: "I love traveling, but I hate dealing with SIM cards and roaming charges. That\'s why I use Commbitz to get an eSIM whenever I go abroad. It's fast, easy, and secure. I can pay in my local currency and get connected in no time.",
  REVIEW_2_AUTHOR: 'Emily Jackson, Berlin',
  REVIEW_2_PLATFORM: 'Facebook',

  REVIEW_3_TEXT: 'The eSIM service has made my international travel so much easier. I simply activated the eSIM on my phone, and I was ready to go in minutes. No need for roaming charges or finding local SIM cards. I’ve had great service in multiple countries, and the data speeds have been consistently excellent.',
  REVIEW_3_AUTHOR: 'Jack Rogers, Sydney',
  REVIEW_3_PLATFORM: 'Twitter',

  REVIEW_4_TEXT: 'As someone who travels frequently, the eSIM has been a game-changer. I no longer have to hunt for local SIM cards or worry about compatibility issues. The eSIM worked seamlessly in every country I visited, and the data speeds were impressive. I’m a loyal customer now!',
  REVIEW_4_AUTHOR: 'Brandon Lee, Vancouver',
  REVIEW_4_PLATFORM: 'Google'
,

  // Slider Steps
  SLIDER_STEP_1_TITLE: 'Get Started with Commbitz',
  SLIDER_STEP_1_SUBTITLE: 'Step 1',
  SLIDER_STEP_1_DESCRIPTION: 'Download:  ',
  SLIDER_STEP_1_INSTALL:"Install the Commbitz app from your device\'s app &",
  SLIDER_STEP_1_PLAYSTORE:"play store.",
  SLIDER_STEP_2_TITLE: 'Get Started with Commbitz',
  SLIDER_STEP_2_SUBTITLE: 'Step 2',
  SLIDER_STEP_2_DESCRIPTION: 'Select your eSIM Plan: ',
  SLIDER_STEP_2_BROWSE :"Browse through available International eSIM card plans within the app. Choose the one that best fits your needs, whether for data, calls, or both.",
 
  SLIDER_STEP_3_TITLE: 'Get Started with Commbitz',
  SLIDER_STEP_3_SUBTITLE: 'Step 3',
  SLIDER_STEP_3_DESCRIPTION: 'Activate it anywhere, hassle-free: '
 ,
 
  SLIDER_STEP_3_FOLOW:"Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world."
  ,
 
 
 
 // App Finder Section
 APP_FINDER_TITLE: 'Get the Commbitz app.',
 APP_FINDER_APPLE_LINK: 'https://apps.apple.com/in/app/commbitz-e-sim/id6572300745',
 APP_FINDER_GOOGLE_LINK: 'https://play.google.com/store/apps/details?id=com.commbitz',
 APP_FINDER_APPLE_IMG_ALT: 'app store apk',
 APP_FINDER_GOOGLE_IMG_ALT: 'play store apk',
 APP_FINDER_DOWNLOAD_TEXT: 'Download or scan the code to install the app.',
 APP_SCANNER_IMG_ALT: 'download commbitz apk',

 NEWS_SECTION_TITLE: 'News',
 FEATURE_LISTING_TITLE: 'Feature Listing',
 LEARN_MORE_TEXT: 'Learn More',

//  banner comp
STAY_NEW_HEADING_PART_1: "Pioneering the World's 1st Early Stage",
STAY_NEW_HEADING_PART_2: "Profitable eSIM Company",

TRAVEL_SMART_HEADING_LINE_1: "Travel Smart",
TRAVEL_SMART_HEADING_LINE_2: "With eSIM.",

AFFORDABLE_SIM_LINE_1: "Affordable eSIM.",
AFFORDABLE_SIM_LINE_2: "Global Connectivity.",
AFFORDABLE_SIM_LINE_3: "Unlimited Freedom.",
// esim comp 
CONNECT_ANYWHERE: "Connect Anywhere, Anytime – Your eSIM, Your Way",
  INTERNATIONAL_ESIM_DESTINATIONS: "International eSIM Destinations",
  SEARCH_DESTINATION_PLACEHOLDER: "Search Destination",
  SEARCH_BUTTON: "Search",
  COUNTRY_NOT_FOUND: "Country not found",
  LOCAL_ESIMS: "Local eSIMs",
  REGIONS: "Regions",
  POPULAR_REGIONS: "Popular Regions",
  INSTALL_PURCHASED_ESIM: "Install your purchased eSIM",
  INSTALL_GUIDELINES: "Install Guidelines",
  GUIDELINES_FOR_ANDROID: "Guidelines for Android",
  GUIDELINES_FOR_IOS: "Guidelines for iOS",
  GO_TO_HELP_DESK: "Go-To Help Desk",
  HELP_DESK_SUBTITLE: "for eSIM installation guide",
  GET_HELP: "Get Help",
  CHECK_DEVICE_COMPATIBILITY: "Check Device Compatibility",
  ESIM_PLANE_NOT_FOUND:"   eSim Plan is not found",
  POPULAR_PLAN_REGION:"Popular Plan in you Region",
  ESIM_APPLY:"Apply",
  ENSUR_YOUR_DEVICE:"Ensure Your Device is Ready for eSIM Activation",
//   topnav bar
GET_ESIM_NOW: "Get your eSIM now !!",
GO_TO_APP: "Go to App",
// navbar
HOME: "Home",
ABOUT_US: "About Us",
ESIM: "Buy eSIM",
BLOGS: "Blogs",
CONTACT_US: "Contact Us",
PROFILE: "Profile",
MY_PURCHASED_ESIM: "My Purchased eSIM",
ORDER_HISTORY: "Order History",
FAQS: "FAQ’s",
TROUBLESHOOTING: "Troubleshooting",
FEATURES: "Features",
LOGOUT: "Logout",
SIGNUP_LOGIN: "Signup / Login",
WELCOME_GUEST: "Hi",
WHERE_ARE_YOU_TRAVELING: "Where are you traveling",
BACK: "Back",
NOTIFICATIONS: "Notifications",
TERMS_CONDITION: "Terms & Condition",
PRIVACY_POLICY: "Privacy Policy",
GUEST:"Guest",
// Footer Section
FOOTER_ABOUT: "About",
FOOTER_PHONE_NUMBER: "+917042696105",
FOOTER_EMAIL: "hello@commbitz.com",
FOOTER_IOT_M2M: "COMMBITZ for IoT & M2M",
FOOTER_BUSINESS: "COMMBITZ for Business",
FOOTER_VIRTUAL_NUMBERS: "COMMBITZ for Virtual Numbers",
FOOTER_POPULAR_COUNTRIES: "Popular Countries",
FOOTER_MORE_INFO: "More Info",
FOOTER_CONTACT: "Contact",
FOOTER_BLOGS: "Blogs",
FOOTER_FAQS: "FAQ's",
FOOTER_TROUBLESHOOT: "Troubleshoot",
FOOTER_NEWSLETTER_PROMPT: "Complete Info, Just a Click Away!",
FOOTER_EMAIL_PLACEHOLDER: "Enter Your Email",
FOOTER_SUBSCRIBE_BUTTON: "Subscribe",

// Chat Section
CHAT_HELP_DESK: "Help Desk",
CHAT_CUSTOMER_SUPPORT: "Customer Support",
CHAT_ACTIVE_NOW: "Active Now",
CHAT_QUESTION_INSTALL_ESIM: "When can I install my eSIM?",
CHAT_QUESTION_SUPPORTED_DEVICES: "What devices Support eSIM?",
CHAT_QUESTION_TOP_UP: "How can I top up an eSIM?",
CHAT_USER_REQUEST: "Can I talk to someone please?",
CHAT_SUPPORT_GREETING: "Hi there! I’m Hannah. ",
CHAT_SUPPORT_GREETING_HELP:"How can I help you?",
CHAT_TIME_NOW: "Hannah・Just now",
CHAT_REPLY_PLACEHOLDER: "Type a reply...",
//  copyright 
FOOTER_COPYRIGHT_TEXT_PART_1: "COMMBITZ",
FOOTER_COPYRIGHT_TEXT_PART_2: ` All Rights Reserved`,
PRIVACY_POLICY_COPYRIGHT: "Privacy Policy",
TERMS_CONDITION_COPYRIGHT: "Terms & Condition",
SHIPPING_POLICY_COPYRIGHT: "Shipping Policy",
REFUND_POLICY_COPYRIGHT: "Refund Policy",
// CHECKOUT
SIM_CARD_VALIDITY: "SIM Card Validity",  
DATA_PACKAGE: "Data Package",  
QUANTITY: "Quantity",  
DESCRIPTION: "Description",  
COVERAGE_COUNTRIES: "Coverage Countries",  
PRICE: "Price",  
TOTAL_AMOUNT: "Total Amount",  
CHECKOUT: "Checkout",  
CANCEL: "Cancel",  
CONFIRM_PURCHASE: "Confirm Purchase",  
EMAIL_ADDRESS: "Email Address",  
PHONE_NUMBER: "Phone Number",  
CUSTOMER_SUPPORT_CHECK: "Customer Support",  
TERMS_AND_CONDITIONS: "Terms and Conditions",  
PRIVACY_POLICY_CHECK: "Privacy Policy",  
SUBSCRIBE_NEWSLETTER: "Subscribe to Newsletter",  
ORDER_HISTORY_CHECK: "Order History",  
ACCOUNT_SETTINGS: "Account Settings",  
LOGOUT_CHECK: "Logout",  
LOGIN: "Login",  
SIGN_UP: "Sign Up",  
PLAN_DETAILS: "Plan Details",
VALIDITY: "Validity",
TOTAL_DATA: "Total Data",
PER_DAY_DATA: "Per day Data",
PAYMENT_SUMMARY: "Payment Summary",
SUB_TOTAL: "Sub-total",
DISCOUNT: "Discount",
TOTAL: "Total",
APPLY_PROMO_CODE: "Apply Promo Code",
PROMO_APPLIED: "Promo code applied",
DEVICE_COMPATIBLE_CONFIRM: "Your device is eSIM compatible and network - unlocked.",
LEARN_MORE: "Learn More",
PROCEED_TO_CHECKOUT: "Proceed to Checkout",
HIGHLIGHTS: "Highlights :",
HIGHLIGHT_1: "Get an instant data connection with a 5G data-only eSIM.",
HIGHLIGHT_2: "The validity of the package will begin once the QR code is scanned and the eSIM is installed.",
HIGHLIGHT_3: "VPN: Auto APN",
HIGHLIGHT_4: "Get connected right after you land in your desired country",
HIGHLIGHT_5: "24/7 business support and customer support services",
HIGHLIGHT_6: "Supports Android, iOS eSIM compatible devices",
HIGHLIGHT_7: "You will receive your eSIM QR within 24 hours via email,",
HIGHLIGHT_8: "Sent by Commbitz!",
TERMS_AND_CONDITIONS_CHECK: "Terms and Conditions",
REFUND_POLICY: "Refund Policy",
MODAL_ID: "changeplan",
MODAL_LABEL: "exampleModalLabel",
PACKAGE_OPTIONS_TITLE: "Package options",
CLEAR_ALL: "Clear All",
SIM_CARD_VALIDITY_CHECK: "SIM card validity",
DATA_PACKAGES: "Data packages",
QUANTITY_CHECK: "Quantity",
UNLIMITED: "Unlimited",
PLANS_NOT_FOUND: "Plans not found",
APPLY_BUTTON_CHECK: "Apply",
MODAL_ID_CHECK: "supportModal",
  MODAL_CLASS: "modal fade show modal-lg",
  ARIA_LABELLEDBY: "exampleModalLabel",
  DEVICE_COMPATIBILITY_TITLE: "Device Compatibility",
  DEVICE_SUPPORT_TITLE: "What device supports eSIM?",
  DEVICE_REQUIREMENTS: "To use as commbitz, a device must meet the following:",
  DEVICE_LIST_TITLE_SUFFIX: "devices that support eSIM ?",
  DEVICE_CHECK_NOTE:
    "You can use our list to see if the device you want to use is eSIM compatible. Note, some regional models may not support eSIMs.",
    // CONTACT US
    META_DESCRIPTION: "Need assistance? Reach out to Commbitz for 24/7 support on eSIM services and global travel connectivity solutions.",
    PAGE_TITLE_CONTACT: "Contact Commbitz | Support for Global Travelers",
    CONTACT_HEADING: "Contact Us",
    CONTACT_INFO_TITLE: "Contact Information",
    LIVE_CHAT_PROMPT: "Say something to start a live chat!",
    HEAD_OFFICE: "Head Office",
    FIRST_NAME_LABEL: "First Name",
    LAST_NAME_LABEL: "Last Name",
    EMAIL_LABEL: "Email",
    PHONE_NUMBER_LABEL: "Phone Number",
    SELECT_SUBJECT: "Select Subject?",
    SELECT_SUBJECT_PLACEHOLDER: "Select Subject",
    MESSAGE_LABEL: "Message",
    MESSAGE_PLACEHOLDER: "Write your message ...",
    SUBMIT_BUTTON_TEXT: "Send Message",
    INVALID_PHONE_MESSAGE: "Invalid phone number",

    // TROUBLESHOOT
    FAQ_LEFT_HEADING: "Do you have more questions?",
    DIRECT_CHAT_LABEL: "Direct Chat",
    EMAIL_SUPPORT_TEXT: "You can email us your question, we are available 24/7 to answer all your questions.",

    // PROFILE
    OVERVIEW_HEADING_PROFILE: "Overview",
    SIGNIN_METHODS_HEADING: "Signin Methods",
    CONTACT_NUMBER_LABEL: "Contact Number",
    EMAIL_ADDRESS_LABEL: "Email Address",
    PASSWORD_LABEL: "Password",
    CHANGE_PASSWORD_BUTTON: "Change password",
    HIDDEN_PASSWORD: "******************",
    AVATAR_ALT_TEXT: "User Avatar",
    EDIT_PROFILE_ALT_TEXT: "Edit Profile",
    EDIT_PROFILE_TOOLTIP: "Click to edit profile",
    EMAIL_PLACEHOLDER: "eureka88@email.com",
    PASSWORD_PLACEHOLDER: "******************",
    SECURE_ACCOUNT_HEADING: "Secure Your Account",
    ENABLE_SECURITY_LINK: "Enable",
    LICENSE_LABEL: "Licence : #45678",
    DEFAULT_USER_IMAGE_ALT: "Default User Image",
    TIMEZONE_LABEL: "Jakarta (GMT+7)",

    // LOGIN PAGE
    ALREADY_HAVE_ACCOUNT: "Already have an account?",
    NEED_HELP: "Need Help?",
    SIGNIN_HEADING: "Signin to",
    EMAIL_OR_PHONE_LABEL: "Email or Phone Number",
    EMAIL_OR_PHONE_PLACEHOLDER: "Please enter your Email or Mobile Number",
    NEXT_BUTTON: "Next",
    PASSWORD_LABEL_LOGIN: "Password",
    PASSWORD_PLACEHOLDER_LOGIN: "Input your password in here",
    FORGOT_PASSWORD_LINK: "Forgot password?",
    LOGIN_WITH_OTP_BUTTON: "Login with OTP",
    LOGIN_BUTTON: "Login",
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    SIGNUP_LINK: "Signup",
    CONTINUE_WITH: "Or continue with",
    GOOGLE_LOGIN: "Google",
    APPLE_LOGIN: "Apple",
    SHOW: "Show OTP Modal",
    RESEND_OTP: "Resend OTP",
    CLOSE_MODAL: "Close Modal",
ENTER_PHONE_NUMBER:"Enter phone number"

    
};