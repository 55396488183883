
import { Suspense, lazy, useEffect, useState } from "react";
import Slider from "react-slick";

import MarkdownIt from "markdown-it";
// Components
import Layout from "../../../components/layout";

import { useNavigate } from "react-router-dom";
import appscntwo from "../../../assets/images/appscntwo.webp";
import appstore from "../../../assets/images/appstore.svg";
import fbfour from "../../../assets/images/fbfour.svg";
import fbone from "../../../assets/images/fbone.svg";
import fbthree from "../../../assets/images/fbthree.svg";
import fbtwo from "../../../assets/images/fbtwo.svg";
import googleplay from "../../../assets/images/googleplay.svg";
import scone from "../../../assets/images/scone.png";
import scthree from "../../../assets/images/scthree.png";
import sctwo from "../../../assets/images/sctwo.png";
import star from "../../../assets/images/stars.svg";
// import { PartnerSection } from "../../../components";
// Styles
import styles from "../styles/home.module.css";

// Images
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getFAQS } from "../../faq/api";
import { FAQ } from "../../faq/interfaces";
import { getCompatibleDevice } from "../../packageOption/api";
import { featureNewsList } from "../api";
// import HomeBanner from "./HomeBanner";
import { STRINGS } from "../../../constants/ strings";
import { GetGeoLocations } from "../../../utils/GetGeoLocation";
import { setLocation } from "../../../redux/slices/locationSlice";
import { trackEvent } from "../../../utils/analytics";
const HomeBanner = lazy(() => import('./HomeBanner'));
const EsimDestination = lazy(() => import('./EsimDestination'));
const PartnerSection = lazy(() => import('../../../components/partnerSection'));
const Home = () => {

  const dispatch = useDispatch();

  const currency = useSelector((state: any) => state?.getCurrency?.currency);
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();
  // const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [bannerEsim, setBannerEsim] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [showSomeDropdown, setShowSomeDropdown] = useState<any>({
    installation: false,
    deviceCompatibility: false,
    activeeSim: false,
  });
  const navigate = useNavigate();
  const [deviceName, setDeviceName] = useState("");
  const [esimNumber, setEsimNumber] = useState("");
  const [showEsimInput, setShowEsimInput] = useState(false);
  const [deviceNameMsg, setDeviceNameMsg] = useState({
    error: "",
    success: "",
  });
  const [featurNewsListData, setFeaturNewsListData] = useState<any>();
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      if ((window.innerWidth <= 768) !== isMobile) {
        setIsMobile(window.innerWidth <= 768);
      }
    };
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);
  useEffect(() => {
    // getFAQS({ page: 1, limit: 3 })
    //   .then((res) => {
    //     const faqs = res.data.faqs.slice(0, 3);
    //     setFaqs(faqs);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader(false);
    //   });
    featureNewsList({ page: 1, limit: 10 })
      .then((res) => {
        setFeaturNewsListData(res?.data);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [currency]);

  const handelCompatibleDevice = () => {
    if (!deviceName) return toast.error("Please enter device name");
    getCompatibleDevice({ deviceName })
      .then((res) => {
        res?.statusCode === 200
          ? toast.success(res?.data?.deviceMessage)
          : toast.error(res?.data?.deviceMessage || "Something went wrong!!!");
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong!!!");
      });
  };
  const handleEsimActivation = () => {
    if (esimNumber.trim()) {
      navigate(`/basic-details/${esimNumber}`);
    } else {
      alert("Please enter a valid eSIM number.");
    }
  };
  var settings =!isMobile ? {
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  }:null;
  // useEffect(() => {
  //   const fetchAndStoreGeoLocation = async () => {
  //     try {
  //       const geoData = await GetGeoLocations();
  //       if (geoData?.latitude && geoData?.longitude) {
  //         // un-comment it if both are required to the local-storage.
          
  //         // localStorage.setItem("latitude", geoData.latitude.toString());
  //         // localStorage.setItem("longitude", geoData.longitude.toString());
         
  //         // lalitude and logitude saved in redux-store 
  //         dispatch(setLocation({ latitude: geoData.latitude.toString(), longitude: geoData.longitude.toString() }));
  //       }
  //     } catch (error) {
  //       console.error("Error fetching geolocation:", error);
  //     }
  //   };

  //   // fetchAndStoreGeoLocation();
  // }, []);
  
  return (
    <Layout>
      <meta
        name="description"
        content="Stay connected globally with Commbitz's international eSIM solutions. Enjoy high-speed, affordable data in 190+ countries with seamless travel and 24/7 support."
      />
      <meta
        name="keywords"
        content="international sim card, buy international sim card, best international travel sim card, global sim card, prepaid esim, sim card for international travel, international roaming sim card, international travel sim, international sim card recharge, best international sim"
      />
      <title>{STRINGS.PAGE_TITLE}</title>
      {/* <HomeBanner bannerEsim={bannerEsim} /> */}
      <Suspense fallback={<div>Loading...</div>}>
     
      {!isMobile && (
        <HomeBanner bannerEsim={bannerEsim} />)}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
      {!isMobile && (
      <PartnerSection />)}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
      {/* <GeoChart /> */}
      <EsimDestination />
      {/* <CountryCoordinates /> */}
      </Suspense>
      <div className={styles.wounderingEsim}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className={styles.WonderingLeft}>
                <h3>{STRINGS.HOME_BANNER_TITLE}</h3>
                <h6>{STRINGS.HOME_BANNER_SUBTITLE}</h6>
              </div>
            </div>
            <div className="col-md-8">
              <div className={styles.WonderingRight}>
                <span className="mediaLinks">
                  <label
                    onClick={() =>
                      setShowSomeDropdown({
                        installation: !showSomeDropdown?.installation,
                        deviceCompatibility: false,
                        activeeSim: false,
                      })
                    }
                  >
                  {STRINGS.INSTALLATION_GUIDE}
                    <i className="fas fa-angle-double-right"></i>
                  </label>
                  {showSomeDropdown?.installation && (
                    <ul>
                      <li>
                        <a
                          href="https://www.youtube.com/shorts/voW6qD5mQKE"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>  {STRINGS.ANDROID_GUIDELINES}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/shorts/yJk3lSCnF2Y"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>  {STRINGS.IOS_GUIDELINES}
                        </a>
                      </li>
                    </ul>
                  )}
                </span>
                <Link className={styles.anchorSupport} to="/contact">
                {STRINGS.CUSTOMER_SUPPORT} <i className="fas fa-angle-double-right"></i>
                </Link>
                <span>
                  <label
                    onClick={() =>
                      setShowSomeDropdown({
                        installation: false,
                        deviceCompatibility:
                          !showSomeDropdown?.deviceCompatibility,
                        activeeSim: false,
                      })
                    }
                  >
                     {STRINGS.DEVICE_COMPATIBILITY}
                    <i className="fas fa-angle-double-right"></i>
                  </label>
                  {showSomeDropdown?.deviceCompatibility && (
                    <ul>
                      <div className={styles.formGroup}>
                        <input
                          type="text"
                          placeholder={STRINGS.ENTER_PHONE_MODEL}
                          onChange={(e) => {
                            setDeviceName(e.target.value);
                          }}
                          value={deviceName}
                        />
                        <button onClick={handelCompatibleDevice}>{STRINGS.APPLY_BUTTON}</button>
                      </div>
                    </ul>
                  )}
                </span>

                {/* Activate eSIM Section */}
                <span>
                  <label
                    onClick={() =>
                      setShowSomeDropdown({
                        installation: false,
                        deviceCompatibility: false,
                        activeeSim: !showSomeDropdown?.activeeSim,
                      })
                    }
                  >
                 {STRINGS.ACTIVATE_ESIM}
                    <i className="fas fa-angle-double-right"></i>
                  </label>
                  {showSomeDropdown?.activeeSim && (
                    <ul>
                      <div className={styles.formGroup}>
                        <input
                          type="text"
                          placeholder={STRINGS.ENTER_ESIM_NUMBER}
                          value={esimNumber}
                          onChange={(e) => setEsimNumber(e.target.value)}
                        />
                        <button onClick={handleEsimActivation}>{STRINGS.APPLY_BUTTON}</button>
                      </div>
                    </ul>
                  )}
                </span>
                {/* End Activate eSIM Section */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
      <div className={styles.reviewOuter}>
        <div className="container">
          <h6>{STRINGS.REVIEWS_TITLE}</h6>
          <h3>{STRINGS.REVIEWS_SUBTITLE}</h3>
          <div className="row">
            <div className="col-md-3">
              <div className={styles.ReviewInner}>
                <img
                  className="starsSvg"
                  width="147"
                  height="25"
                  src={star}
                  alt=""
                  loading="lazy"
                  decoding="async" // Add this
             
                />
                <p>
                {STRINGS.REVIEW_1_TEXT}
                </p>
                <h5>  {STRINGS.REVIEW_1_AUTHOR}</h5>
                <span>
                  {" "}
                  <img
                    className="starsSvg"
                    width="28"
                    height="28"
                    src={fbone}
                    alt=""
                    loading="lazy"
                    decoding="async" // Add this
                   
                  />{" "}
                 {STRINGS.REVIEW_1_PLATFORM}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className={styles.ReviewInner}>
                <img
                  className="starsSvg"
                  width="147"
                  height="25"
                  src={star}
                  alt=""
                  loading="lazy"
                  decoding="async" // Add this
                 
                />
                <p>
                {STRINGS.REVIEW_2_TEXT}
                </p>
                <h5> {STRINGS.REVIEW_2_AUTHOR}</h5>
                <span>
                  {" "}
                  <img
                    className="starsSvg"
                    src={fbtwo}
                    width="28"
                    height="28"
                    alt=""
                    loading="lazy"
                    decoding="async" // Add this
                   
                  />{" "}
                {STRINGS.REVIEW_2_PLATFORM}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className={styles.ReviewInner}>
                <img
                  className="starsSvg"
                  width="147"
                  height="25"
                  src={star}
                  alt=""
                  loading="lazy"
                  decoding="async" // Add this
                 
                />
                <p>
                {STRINGS.REVIEW_3_TEXT}
                </p>
                <h5>   {STRINGS.REVIEW_3_AUTHOR}</h5>
                <span>
              
                  <img
                    className="starsSvg"
                    src={fbthree}
                    width="28"
                    height="28"
                    alt=""
                    loading="lazy"
                    decoding="async" // Add this
                   
                  />
                {STRINGS.REVIEW_3_PLATFORM}
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div className={styles.ReviewInner}>
                <img
                  className="starsSvg"
                  width="147"
                  height="25"
                  src={star}
                  alt=""
                  loading="lazy"
                  decoding="async" // Add this
                 
                />
                <p>
                {STRINGS.REVIEW_4_TEXT}
                </p>
                <h5>   {STRINGS.REVIEW_4_AUTHOR}</h5>
                <span>
              
                  <img
                    className="starsSvg"
                    src={fbfour}
                    width="28"
                    height="28"
                    alt=""
                    loading="lazy"
                    decoding="async" // Add this
                   
                  />{" "}
                   {STRINGS.REVIEW_4_PLATFORM}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>)}
      {!isMobile && (
      <div className={styles.combitStep}>
        <div className="container">
          <div className="conitSlider">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <Slider
                  speed={700}
                  fade={true}
                  asNavFor={nav2}
                  ref={(slider1) => setNav1(slider1)}
                >
                  <div className={styles.Leftslider}>
                    <h3> {STRINGS.SLIDER_STEP_1_TITLE}</h3>
                    <span>{STRINGS.SLIDER_STEP_1_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b>{STRINGS.SLIDER_STEP_1_DESCRIPTION}</b>
                        <br /> {STRINGS.SLIDER_STEP_1_INSTALL}
                        {STRINGS.SLIDER_STEP_1_PLAYSTORE}
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Leftslider}>
                    <h3> {STRINGS.SLIDER_STEP_2_TITLE}</h3>
                    <span>{STRINGS.SLIDER_STEP_2_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b>{STRINGS.SLIDER_STEP_2_DESCRIPTION}</b>
                        <br /> {STRINGS.SLIDER_STEP_2_BROWSE}
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Leftslider}>
                    <h3> {STRINGS.SLIDER_STEP_3_TITLE}</h3>
                    <span> {STRINGS.SLIDER_STEP_3_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b> {STRINGS.SLIDER_STEP_3_DESCRIPTION}</b>
                        <br />  {STRINGS.SLIDER_STEP_3_FOLOW}
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Leftslider}>
                    <h3>{STRINGS.SLIDER_STEP_1_TITLE}</h3>
                    <span>{STRINGS.SLIDER_STEP_1_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b>{STRINGS.SLIDER_STEP_1_DESCRIPTION}</b>
                        <br /> {STRINGS.SLIDER_STEP_1_INSTALL}
                        {STRINGS.SLIDER_STEP_1_PLAYSTORE}
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Leftslider}>
                    <h3> {STRINGS.SLIDER_STEP_1_TITLE}</h3>
                    <span> {STRINGS.SLIDER_STEP_2_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b>{STRINGS.SLIDER_STEP_2_DESCRIPTION}</b>
                        <br />{STRINGS.SLIDER_STEP_2_BROWSE}
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Leftslider}>
                    <h3>{STRINGS.SLIDER_STEP_1_TITLE}</h3>
                    <span>{STRINGS.SLIDER_STEP_3_SUBTITLE}</span>
                    <ul>
                      <li>
                        <b>{STRINGS.SLIDER_STEP_3_DESCRIPTION}</b>
                        <br /> {STRINGS.SLIDER_STEP_3_FOLOW}
                      </li>
                    </ul>
                  </div>
                </Slider>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="NavSlide">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={3}
                    autoplay={true}
                    cssEase={"linear"}
                    speed={700}
                    infinite={true}
                    dots={true}
                    swipeToSlide={true}
                    centerMode={true}
                    focusOnSelect={false}
                  >
                    <div className={styles.Rightslider}>
                      <img
                        src={scone}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                    <div className={styles.Rightslider}>
                      <img
                        src={sctwo}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                    <div className={styles.Rightslider}>
                      <img
                        src={scthree}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                    <div className={styles.Rightslider}>
                      <img
                        src={scone}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                    <div className={styles.Rightslider}>
                      <img
                        src={sctwo}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                    <div className={styles.Rightslider}>
                      <img
                        src={scthree}
                        width="640"
                        height="360"
                        alt="Buy international eSim online"
                        loading="lazy"
                        decoding="async" // Add this
                       
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}
      {!isMobile && (
      <div className={styles.appFinder}>
        <div className="container">
          <div className={styles.appDownOuter}>
            <div className={styles.appFinderInner}>
              <h3>{STRINGS.APP_FINDER_TITLE}</h3>
              <div className={styles.appIc}>
                <span>
                  <a
                    href="https://apps.apple.com/in/app/commbitz-e-sim/id6572300745"
                    target="_blank"
                  >
                    <img
                      src={appstore}
                      width="168"
                      height="50"
                      alt="app store apk"
                      loading="lazy"
                      decoding="async" // Add this
                     
                    />
                  </a>
                </span>
                <span>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.commbitz"
                    target="_blank"
                  >
                    <img
                      src={googleplay}
                      width="168"
                      height="50"
                      alt="play store apk"
                      loading="lazy"
                      decoding="async" // Add this
                     
                    />
                  </a>
                </span>
              </div>
              <p>{STRINGS.APP_FINDER_DOWNLOAD_TEXT}</p>
            </div>
            <div className={styles.AppScanner}>
              <img
                src={appscntwo}
                width="493"
                height="513"
                alt="download commbitz apk"
                loading="lazy"
                decoding="async" // Add this
               
              />
            </div>
          </div>
        </div>
      </div>)}
      {!isMobile && (
      <div className={styles.newsService}>
        <div className="container">
          <h6>{STRINGS.NEWS_SECTION_TITLE}</h6>
          <h3>{STRINGS.FEATURE_LISTING_TITLE}</h3>
          <div className="row fesrureSlider">
            <Slider {...settings}>
              {featurNewsListData &&
                featurNewsListData?.map((item: any, index: number) => (
                  <div className={styles.newInner}>
                    <img
                      width="160"
                      height="79"
                      src={item?.featureImageUrl}
                      alt=""
                      loading="lazy"
                      decoding="async" // Add this
                     
                      
                    />
                    <div className={styles.newText}>
                      <a href={item?.featureUrlLink} target="_blank">
                      {STRINGS.LEARN_MORE_TEXT}<i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>)}
    </Layout>
  );
};

export default Home;
