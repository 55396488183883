import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "./assets/fonts/stylesheet.css"

import AppRoutes from "./routes";

import { Toaster } from 'react-hot-toast';
import { trackEvent } from './utils/analytics';
// const AppRoutes = lazy(() => import("./routes"));

function App() {
	useEffect(() => {
		// Check if "App Installed" has already been tracked
		if (!localStorage.getItem("clevertap_app_installed")) {
		  trackEvent("App Installed", { page: "Home" });
		  localStorage.setItem("clevertap_app_installed", "true");
		}
	
		// Always track "Home Screen" when the user lands on it
		trackEvent("Home Screen", { page: "Home" });
	
	  }, []);
	return (
		<Router>
			  {/* <Suspense fallback={<div>Loading routes...</div>}> */}
			<AppRoutes />
			{/* </Suspense> */}
			<Toaster
  position="top-right"
  reverseOrder={false}
/>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</Router>
	);
}

export default App;
