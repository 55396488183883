import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import styles from "../styles/style.module.css";
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
} from "../../../redux/slices/userSlice";
import MainLoader from "../../../components/mainLoader";
// Components
import Layout from "../../../components/layout";
import chip from "../../../assets/images/chip.png";
import StripePaymentModal from "../../../components/modals/StripePayment";
import { Skeleton } from "antd";
import { getDocumentStatus } from "../../checkout/api";
//API
import {
  gapplyOfferForYou,
  getDevicesName,
  getFilterData,
  getOfferForYou,
  getOrderDetails,
  getPromos,
  purchaseOrder,
  razorpayAddOrder,
  stripePaymentIntent,
  updateOrder,
} from "../api";
import { CheckoutIcon } from "../../../assets/images";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { getUserData } from "../../../redux/api";
import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
import { PAYMENT_METHOD } from "../../../utils/constants";
import useAuth from "../../../lib/hooks/useAuth";
import { decodeBase64 } from "../../../utils/secureToken";
import { API_URL, NIYO_URL } from "../../../config";
import Swal from "sweetalert2";

import { getPreOrder, getbundles } from "../../home/api";
import { getBundleFilterData } from "../../esim/api";
import { getCompatibleDevice } from "../../packageOption/api";
import { GetGeoLocations } from "../../../utils/GetGeoLocation";
import { addOrder } from "../../document/api";
import { trackEvent } from "../../../utils/analytics";
import GuestLogin from "../components/GuestLogin";

declare global {
  interface Window {
    Razorpay: any;
  }
}
interface DurationItem {
  duration: number;
  package: number[];
}
const Checkout = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const paymentGateway: any = queryParams.get("paymentGateway");
  const urlToken: any = queryParams.get("token");
  const dispatch = useDispatch();
  const [deviceDropDown, setDeviceDropDown] = useState<boolean>(false);
  const [deviceDropDownPopup, setDeviceDropDownPopup] =
    useState<boolean>(false);
  const [compatibleError, setCompatibleError] = useState<boolean>(false);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { data } = useSelector((state: any) => state.getUserData);
  const guestData = useSelector((state: any) => state.guestLogin);
  const [order, setOrderDetails] = useState<any>({});
  const [orders, setOrderDetailss] = useState<any>({});
  const [promoCode, setPromoCode] = useState<string>("");
  const [promos, setPromos] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [promoError, setPromoError] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState({
    clientSecret: "",
    paymentId: "",
    setupIntent: "",
    currency: "",
    label: "Total",
    amount: 0,
  });

  const [minimumSpend, setMinimumSpend] = useState<any>(0);
  const [paymentMethodError, setPaymentMethodError] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [loader2, setLoader2] = useState<boolean>(true);
  const [loader3, setLoader3] = useState<boolean>(false);
  const [loader4, setLoader4] = useState<Set<string>>(new Set());
  const [finalPrice, setFinalPrice] = useState(0);
  const [walletAmShow, setWalletAmShow] = useState(false);
  // const [walletAmError, setWalletAmError] = useState('');
  const [walletMethod, setWalletMethod] = useState(0);
  const [walletInput, setWalletInput] = useState(false);
  const [pageError, setPageError] = useState("");
  const currency = useSelector((state: any) => state?.getCurrency?.currency);
  const current_url: any = window.localStorage.getItem("current_url");
  const [showPackageOption, setShowPackageOption] = useState<boolean>(false);
  const [bundleDetails, setBundleDetails] = useState<any>({});
  const [days, setDays] = useState<any>({});
  const [bundleId, setBundleId] = useState<string>("");
  const [orderId, setOrderId] = useState<string>(params.id as string);
  const [bundleAmount, setBundleAmount] = useState<number>(0);
  const [bundleFilterAmount, setBundleFilterAmount] = useState<any>();

  const [bundlePlans, setBundlePlans] = useState<number>(0);
  const secureToken: any = decodeBase64(urlToken);
  const [bundleQty, setBundleQty] = useState<number>(1);
  const [esimFilterData, setEsimFilterData] = useState<any>();
  const [offersForYou, setOffersForYou] = useState<any>();
  const [deviceNameMsg, setDeviceNameMsg] = useState({
    error: "",
    success: "",
  });
  const [deviceName, setDeviceName] = useState("");
  const [deviceNameData, setDeviceNameData] = useState<any>();
  type LocationData = {
    latitude: number;
    longitude: number;
  };
  const [locations, setLocations] = useState<LocationData | null>(null);
  const filteredCountry = window.localStorage.getItem("countryName");
  const [isPending, setIsPending] = useState(false);
  const [durations, setDurations] = useState<DurationItem[]>([]);
  const [packageList, setPackageList] = useState<number[]>([]);
  const [apiResponse, setApiResponse] = useState<any>(null);
  const [countryNam, setCountryNam] = useState<string>("");
  const [currencySymbol, setCurrencySymbol] = useState<string>("");

  const [planPrice, setPlanPrice] = useState<number>(0);
  const [finalPrices, setFinalPrices] = useState<number>(0);
  const [orderQuantity, setOrderQuantity] = useState<number>(1);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [flag, setFlag] = useState<any | null>(null);
  const [coverageCount, setCoverageCount] = useState(0);
  const [bundlePlanss, setBundlePlanss] = useState<number | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<number | null>(null);
  const [newOrderId, setNewOrderId] = useState<string | null>(null);
  const [selectedBundleId, setSelectedBundleId] = useState<string | null>(null);
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBundleIdState, setSelectedBundleIdState] = useState(null);
  // const [selectedPaymentMethod, setPaymentMethod] =
  // useState<number>(selectedPaymentGateway ?? 0);
  // console.log("query paramas", queryBundleId)
  const [geoCoordinates, setGeoCoordinates] = useState<{
    lat: number | null;
    long: number | null;
  }>({
    lat: null,
    long: null,
  });
  const [userDataa, setUserDataa] = useState({
    phoneNumber: "",
    email: "",
    fullName: "",
  });
  const [geoQuerySent, setGeoQuerySent] = useState(false);
  const [selectedPack, setSelectedPack] = useState<{
    duration: number;
    pkg: number;
    bundeId: string;
  } | null>(null);
  const getQueryParam = (param: any) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
console.log(guestData,"reduxxxxx")
  // Get the parameters from the query string
  const queryBundleId = getQueryParam("bundleId");
  const url = queryBundleId
    ? `${API_URL}/${countryNam}?bundleId=${queryBundleId}`
    : `${API_URL}/${countryNam}`;
  console.log("query: ", queryBundleId);
  const openModal = () => {
    // setIsOpen(false);
    setTimeout(() => {
      setModalIsOpen(true);
    }, 100);
  };

 
  const closeModal = async () => {
    // Ensure this is done first
    setModalIsOpen(false);  // Close the modal
    // window.location.reload(); // Reload after checkout
  handleCheckoutGuest();
  };
  
  // const preOrderApi = (qty: any, bundleId: any) => {
  //   const query: any = {
  //     search: params?.countryName,
  //     quantity: qty,
  //     bundleId,
  //     // Keeping search parameter
  //   };

  //   // Only add latitude and longitude if they're available
  //   // if (lat && long) {
  //   //   query.lat = lat;
  //   //   query.long = long;
  //   // }
  //   getPreOrder(query)
  //     .then((res: any) => {
  //       setApiResponse(res);
  //       trackEvent("Plan Viewed", {});
  //       const durationData: DurationItem[] =
  //         res?.baseResult?.[0]?.durationData || [];

  //       if (durationData.length > 0) {
  //         const sortedDurations = durationData.sort(
  //           (a, b) => a.duration - b.duration
  //         );
  //         setDurations(sortedDurations);
  //         const initialDuration =  queryBundleId? sortedDurations.filter(sd=>sd.duration===Number(params.id))[0]: sortedDurations[0];
  //         const selectedBundleIdd = res?.bundles.find(
  //           (bundle: any) =>
  //             bundle?.duration === initialDuration.duration &&
  //             bundle?.dataAmount === initialDuration.package[0]
  //         );
  //         if (!selectedBundleId) {
  //           setSelectedBundleId(selectedBundleIdd._id);
  //         }
  //         else {
  //           window.history.replaceState(null, "", `/${countryNam}`);
  //         }
  //         if (!bundlePlanss) {
  //           setBundlePlanss(queryBundleId ? Number(params.id): initialDuration.duration);
  //         }
  //         if (packageList.length === 0) {
  //           setPackageList(initialDuration.package);
  //         }

  //         if (!selectedPackage) {
  //           setSelectedPackage(initialDuration.package[0]);
  //         }
  //       }

  //       if (res?.bundles?.length > 0) {
  //         setCountryNam(res.bundles[0].name);
  //       }

  //       if (res?.orderDataToSave) {
  //         const {
  //           priceSymbol = "$",
  //           planPrice = 0,
  //           quantity = 1,
  //           finalPrice = 0,
  //         } = res.orderDataToSave;
  //         setCurrencySymbol(priceSymbol);
  //         setPlanPrice(planPrice);
  //         setOrderQuantity(quantity);
  //         setFinalPrices(finalPrice);
  //       }

  //       const coverageData = res?.baseResult?.[0]?.coverageCountries?.[0];
  //       if (coverageData) {
  //         // setFlag(coverageData.flagImageUrl);
  //         setCoverageCount(coverageData.coverageCountriesCount);
  //       }

  //       const coverageDataFlag = res?.bundles?.[0]?.coverageCountriesData;

  //       if (coverageDataFlag) {
  //         // Now, check if the full flag URL exists in the object
  //         const flagsWithCountries = coverageDataFlag.map((country: any) => ({
  //           flagUrl: country.flagImageUrlFull,
  //           countryName: country.name,
  //         }));
  //         setFlag(flagsWithCountries);
  //       }
  //       trackEvent("Plan Added to Cart", {
  //         planId: selectedBundleId,
  //         planName: bundlePlanss,
  //         price:selectedPackage
  //     });
  //     })

  //     .catch((error) => console.error("API Error:", error))
  //     .finally(() => {
  //       setLoader2(false);
  //       setIsPending(false);
  //       setLoader3(false);
  //     });
  // };
  // const preOrderApi = (qty: any, lat: any, long: any, bundleId: any) => {
  const preOrderApi = (qty: any, bundleId: any) => {
    // setLoader4(true);
    setLoader2(true);
    const query: any = {
      search: params?.countryName,
      quantity: qty,
      bundleId: bundleId,
      // Keeping search parameter
    };

    // Only add latitude and longitude if they're available
    // if (lat && long) {
    //   query.lat = lat;
    //   query.long = long;
    // }
    getPreOrder(query)
      .then((res: any) => {
        setApiResponse(res);
        trackEvent("Plan Viewed", {});
        const durationData: DurationItem[] =
          res?.baseResult?.[0]?.durationData || [];

        if (durationData.length > 0) {
          const sortedDurations = durationData.sort(
            (a, b) => a.duration - b.duration
          );

          setDurations(sortedDurations);
          const selectedBundle = res?.bundles.find(
            (bundle: any) => bundle?._id === queryBundleId
          );
          const initialDuration = queryBundleId
            ? sortedDurations.filter(
                (sd) => sd.duration === selectedBundle?.duration
              )[0]
            : sortedDurations[0];
          let initialPackage = selectedBundle
            ? selectedBundle.dataAmount
            : initialDuration.package[0];
          const selectedBundleIdd = res?.bundles.find(
            (bundle: any) =>
              bundle?.duration === initialDuration.duration &&
              bundle?.dataAmount === initialDuration.package[0]
          );
          if (!selectedPackage) {
            setSelectedPackage(queryBundleId  ? initialDuration.package.find(p=>p===selectedBundle.dataAmount) || null: initialDuration.package[0]);
         
          } else {
            window.history.replaceState(null, "", `/${countryNam}`);
          }
          if (!bundlePlanss) {
            setBundlePlanss(initialDuration.duration);
          }
          if (packageList.length === 0) {
            setPackageList(initialDuration.package);
          }

          if (!selectedPackage) {
            setSelectedPackage(
              queryBundleId
                ? initialDuration.package.find(
                    (p) => p === selectedBundle.dataAmount
                  ) || null
                : initialDuration.package[0]
            );
          }
          // ✅ Set initial selected package (handling `queryBundleId`)

          if (!selectedPack) {
            setSelectedPack({
              duration: initialDuration.duration,
              pkg: initialPackage ?? 0,
              bundeId: selectedBundleIdd._id,
            });
          }
        }

        if (res?.bundles?.length > 0) {
          setCountryNam(res.bundles[0].name);
        }

        if (res?.orderDataToSave) {
          const {
            priceSymbol = "$",
            planPrice = 0,
            quantity = 1,
            finalPrice = 0,
          } = res.orderDataToSave;
          setCurrencySymbol(priceSymbol);
          setPlanPrice(planPrice);
          setOrderQuantity(quantity);
          setFinalPrices(finalPrice);
        }

        const coverageData = res?.coverageCountries;
        if (coverageData) {
          // Set the coverage count (number of countries)
          setCoverageCount(coverageData.length);
    
          // Map over countries to extract flags and names
          const flagsWithCountries = coverageData.map((country: any) => ({
            flagUrl: country.flagImageUrlFull,
            countryName: country.name,
          }));
          setFlag(flagsWithCountries);
        }
        trackEvent("Plan Added to Cart", {
          planId: selectedBundleId,
          planName: bundlePlanss,
          price: selectedPackage,
        });
      })

      .catch((error) => console.error("API Error:", error))
      .finally(() => {
        // setLoader4(false); 
        setLoader2(false);
        setIsPending(false);
        setLoader3(false);
      });
  };

  useEffect(() => {
    const fetchGeoLocation = async (bundleId: any) => {
      let geoData = null;
      let resGeo = null;
      // Fetch geolocation data
      try {
        geoData = await GetGeoLocations();
      } catch (e) {
      } finally {
        if (geoData) {
          resGeo = await getDocumentStatus(geoData);
        }
        const paymentGatewayType = resGeo?.data?.paymentGatewayType; // Store value for later use
        setSelectedPaymentGateway(paymentGatewayType);
        if (geoData?.latitude && geoData?.longitude) {
          setGeoCoordinates({
            lat: geoData.latitude,
            long: geoData.longitude,
          });
          preOrderApi(
            1,
            // geoData.latitude,
            // geoData?.longitude,
            bundleId
          );
        } else {
          preOrderApi(1, bundleId);
        } // null, null,

        // const query: any = {
        //         search: params?.countryName,  // Keeping search parameter
        //       };

        //       // Only add latitude and longitude if they're available
        //       if (geoData && geoData.latitude && geoData.longitude) {
        //         query.lat = geoData.latitude;
        //         query.long = geoData.longitude;
        //       }
        // getPreOrder(query)
        //       .then((res: any) => {
        //         setApiResponse(res);

        //         const durationData: DurationItem[] = res?.baseResult?.[0]?.durationData || [];
        //         if (durationData.length > 0) {
        //           const sortedDurations = durationData.sort((a, b) => a.duration - b.duration);
        //           setDurations(sortedDurations);
        //           const initialDuration = sortedDurations[0];
        //           setBundlePlanss(initialDuration.duration);
        //           setPackageList(initialDuration.package);
        //           setSelectedPackage(initialDuration.package[0]);
        //         }

        //         if (res?.bundles?.length > 0) {
        //           setCountryNam(res.bundles[0].name);
        //         }

        //         if (res?.orderDataToSave) {
        //           const { priceSymbol = "$", planPrice = 0, quantity = 1 } = res.orderDataToSave;
        //           setCurrencySymbol(priceSymbol);
        //           setPlanPrice(planPrice);
        //           setOrderQuantity(quantity);
        //         }

        //         const coverageData = res?.baseResult?.[0]?.coverageCountries?.[0];
        //         if (coverageData) {
        //           setFlag(coverageData.flagImageUrl);
        //           setCoverageCount(coverageData.coverageCountriesCount);
        //         }
        //       })
        //       .catch((error) => console.error("API Error:", error))
        //       .finally(() => {
        //         setLoader2(false);
        //         setIsPending(false);
        //         setLoader3(false);
        //       });
      }
      // Fetch document status using geoData
    };
    // getBundleIdByDay(30).then(bundlId=>{
    //   fetchGeoLocation(bundlId);
    // })
    fetchGeoLocation(queryBundleId ?? null);
  }, []); // This effect runs once on mount when no dependencies are provided
  // useEffect(() => {
  //   // Check if params.countryName is available and handle geo location as optional

  //   if (params.countryName) {
  //     const query: any = {
  //       search: params.countryName,  // Keeping search parameter
  //     };

  //     // Only add latitude and longitude if they're available
  //     if (geoCoordinates.lat && geoCoordinates.long) {
  //       query.lat = geoCoordinates.lat;
  //       query.long = geoCoordinates.long;
  //     }

  //     setLoader2(true);
  //     setIsPending(true);
  //     setLoader3(true);

  //     getPreOrder(query)
  //       .then((res: any) => {
  //         setApiResponse(res);

  //         const durationData: DurationItem[] = res?.baseResult?.[0]?.durationData || [];
  //         if (durationData.length > 0) {
  //           const sortedDurations = durationData.sort((a, b) => a.duration - b.duration);
  //           setDurations(sortedDurations);
  //           const initialDuration = sortedDurations[0];
  //           setBundlePlanss(initialDuration.duration);
  //           setPackageList(initialDuration.package);
  //           setSelectedPackage(initialDuration.package[0]);
  //         }

  //         if (res?.bundles?.length > 0) {
  //           setCountryNam(res.bundles[0].name);
  //         }

  //         if (res?.orderDataToSave) {
  //           const { priceSymbol = "$", planPrice = 0, quantity = 1 } = res.orderDataToSave;
  //           setCurrencySymbol(priceSymbol);
  //           setPlanPrice(planPrice);
  //           setOrderQuantity(quantity);
  //         }

  //         const coverageData = res?.baseResult?.[0]?.coverageCountries?.[0];
  //         if (coverageData) {
  //           setFlag(coverageData.flagImageUrl);
  //           setCoverageCount(coverageData.coverageCountriesCount);
  //         }
  //       })
  //       .catch((error) => console.error("API Error:", error))
  //       .finally(() => {
  //         setLoad91186er2(false);
  //         setIsPending(false);
  //         setLoader3(false);
  //       });
  //   }
  // }, [geoCoordinates.lat, geoCoordinates.long, params.countryName]);
  // Add dependencies

  const increaseQuantity = () => {
    preOrderApi(
      selectedQuantity + 1,
      // geoCoordinates?.lat,
      // geoCoordinates?.long,
   selectedPack?.bundeId,
    );

    setSelectedQuantity((prev) => prev + 1);
  };

  // Function to decrease quantity (ensure it doesn't go below 1)
  const decreaseQuantity = () => {
    preOrderApi(
      selectedQuantity - 1,
      // geoCoordinates?.lat,
      // geoCoordinates?.long,
   selectedPack?.bundeId,
    );

    setSelectedQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleDurationChange = (duration: number) => {
    setBundlePlanss(duration);
    const selectedDuration = durations.find(
      (item) => item.duration === duration
    );
    const selectedBundle = apiResponse?.bundles.find(
      (bundle: any) =>
        bundle?.duration === duration &&
        bundle?.dataAmount === selectedDuration?.package[0]
    );
    setSelectedBundleId(selectedBundle?._id);
    preOrderApi(
      selectedQuantity,
      // geoCoordinates?.lat,
      // geoCoordinates?.long,
      selectedBundle?._id
    );

    if (selectedDuration) {
      setPackageList(selectedDuration.package);
      setSelectedPackage(selectedDuration.package[0]); // Select the first package by default
    }
  };

  const handlePackageChange = (pkg: number) => {
    const selectedBundle = apiResponse?.bundles.find(
      (bundle: any) =>
        bundle?.duration === bundlePlanss && bundle?.dataAmount === pkg
    );
    setSelectedBundleId(selectedBundle?._id);
    preOrderApi(
      selectedQuantity,
      // geoCoordinates?.lat,
      // geoCoordinates?.long,
      selectedBundle?._id
    );
    setSelectedPackage(pkg);
  };

  const getTotalPrice = () =>
    planPrice ? (planPrice * (selectedQuantity || 1)).toFixed(0) : "0.00";
  const placeOrder = async (qty: any, apiResp: any) => {
    try {
      const selectedBundle = apiResp?.bundles.find(
        (bundle: any) =>
          bundle?.duration === bundlePlanss &&
          bundle?.dataAmount === selectedPackage
      );
      const orderPayload: any = {
        bundleId: selectedBundle?._id,
        quantity: qty,
      };
      if (geoCoordinates.lat && geoCoordinates.long) {
        orderPayload.lat = geoCoordinates.lat;
        orderPayload.long = geoCoordinates.long;
      }
      const response = await addOrder(orderPayload); // Make the API call
      if (response?.data?.orderId) {
        localStorage.setItem("oderId", response.data.orderId);
        setNewOrderId(response.data.orderId); // Set the orderId from the response
      }
    } catch (error) {
      console.error("Order placement failed:", error);
    }
  };
  // useEffect(() => {
  //   const selectedBundle = apiResponse?.bundles.find(
  //     (bundle: any) => bundle?.duration === bundlePlanss && bundle?.dataAmount === selectedPackage
  //   );
  //   console.log(selectedBundle?._id,"akshsayyy")

  //   if(selectedQuantity || apiResponse){
  //     preOrderApi(selectedQuantity,geoCoordinates.lat, geoCoordinates.long,selectedBundle?._id);
  //   }

  // }, [selectedPackage, selectedQuantity,]);
  // useEffect(() => {

  //   if(selectedQuantity || apiResponse){
  //     placeOrder(selectedQuantity, apiResponse);
  //   }
  //   // Call the placeOrder function on mount
  // }, [apiResponse, bundlePlanss, selectedPackage, selectedQuantity]);
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const locationData: LocationData = await GetGeoLocations();

        if (!locationData.latitude || !locationData.longitude) {
          throw new Error("Location data is missing");
        }

        setLocations(locationData);
      } catch (error) {
        console.error("Error getting location:", error);
      }
    };
    window.scrollTo(0, 0);
    fetchLocation();
  }, []);

  useEffect(() => {
    getOfferForYou({ page: 1, limit: 10 })
      .then((res) => {
        setOffersForYou(res?.data);
      })
      .catch((error) => {
        console.log(error, " getOfferForYou ");
        setLoader(false);
      });

    // getOrderDetails(newOrderId as string)
    //   .then((res: any) => {
    //     setBundleId(res?.data?.bundleId);
    //     setBundleAmount(res?.data?.dataAmount);
    //     setBundlePlans(res?.data?.duration);
    //     setBundleDetails(res?.data);
    //     setLoader2(true);
    //     getFilterData({ search: res?.data?.name }) //res?.data?.name filteredCountry
    //       .then((res: any) => {
    //         setEsimFilterData(res?.data[0]);
    //         setLoader2(false);
    //       })
    //       .catch((error) => {
    //         toast.error(error?.data?.message || "Something went wrong!");
    //         setLoader2(false);
    //       });

    //     if (res?.data?.finalPrice <= 0) {
    //       setSelectedPaymentGateway(0);
    //     }

    //     setFinalPrice(res?.data?.finalPrice);
    //     if (urlToken && secureToken != null) setPromoCode(res?.data?.promoCode);
    //     // If everything is fine, set the order details
    //     setOrderDetails(res?.data);
    //   })
    //   .catch((error) => {
    //     // if (error?.data?.statusCode === 400) {
    //     //   setPageError("Plans is not found!");
    //     // }
    //     setLoader(false);
    //   })
    //   .finally(() => {
    //     setLoader(false);
    //   });

    getDevicesName({ page: 1, limit: 10 })
      .then((res) => {
        setDeviceNameData(res?.data[0]?.devices);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, " getDevicesName ");
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (urlToken && secureToken?.error) {
      window.localStorage.removeItem("niyoToken");
      if (secureToken?.error) toast.error("Token not exist");

      setTimeout(() => {
        window.location.href = NIYO_URL;
      }, 5000);
    }
    if (urlToken && !secureToken?.error)
      window.localStorage.setItem("niyoToken", secureToken);

    selectedPaymentGateway == PAYMENT_METHOD.RAZORPAY
      ? setSelectedPaymentGateway(2)
      : setSelectedPaymentGateway(1);

    // if (current_url) {
    //   const payload: any = {
    //     bundleId: bundleId,
    //     orderId: newOrderId as string,
    //     lat: locations?.latitude, // Default to 0 if null
    //     long: locations?.longitude, // Default to 0 if null
    //   };

    //   updateOrder(payload).then((res) => {
    //     window.localStorage.removeItem("current_url");
    //     setLoader(false);
    //   });
    // }


    if (isAuthenticated && !urlToken)
      getPromos()
        .then((res) => {
          setPromos(res?.data);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error, "promos");
        });
    // if(!loader) {
    //     getOrderDetails(newOrderId as string)
    //       .then((res: any) => {
    //         setBundleId(res?.data?.bundleId);
    //         setBundleAmount(res?.data?.dataAmount);
    //         setBundlePlans(res?.data?.duration);
    //         setBundleDetails(res?.data);

    //         if (res?.data?.finalPrice <= 0) {
    //           setPaymentMethod(0);
    //         }

    //         setFinalPrice(res?.data?.finalPrice);
    //         if (urlToken && secureToken != null) setPromoCode(res?.data?.promoCode);
    //         // If everything is fine, set the order details
    //         setOrderDetails(res?.data);
    //       })
    //       .catch((error) => {
    //         if (error?.data?.statusCode === 400) {
    //           setPageError("Plans is not found!");
    //         }
    //         setLoader(false);
    //       })
    //       .finally(() => {
    //         setLoader(false);
    //       });
    //     }
  }, [isAuthenticated]);

  // const increaseQuantity = async () => {
  //   setLoader(true);
  //   const payload = {
  //     bundleId: bundleId,
  //     orderId: newOrderId as string,
  //     quantity: order.quantity + 1,
  //     promoCode,
  //     lat: locations?.latitude, // Default to 0 if null
  //     long: locations?.longitude, // Default to 0 if null
  //   };

  //   updateOrder(payload).then((res) => {
  //     setFinalPrice(res?.data?.finalPrice);
  //     if (res?.data?.finalPrice <= 0) {
  //       setPaymentMethod(0);
  //     } else {
  //       setPaymentMethod(selectedPaymentMethod);
  //     }
  //     setOrderDetails(res.data);
  //     setLoader(false);
  //   });
  // };

  // const decreaseQuantity = async () => {
  //   setLoader(true);
  //   if (order.quantity > 1) {
  //     const qty = order.quantity - 1;
  //     const payload: any = {
  //       orderId: newOrderId as string,
  //       bundleId: bundleId,
  //       quantity: qty,
  //       promoCode: promoCode, // Assuming order.promoCode is defined somewhere
  //       lat: locations?.latitude, // Default to 0 if null
  //       long: locations?.longitude, // Default to 0 if null
  //     };

  //     if (minimumSpend >= order.planPrice / qty) {
  //       delete payload.promoCode;
  //     }
  //     updateOrder(payload).then((res) => {
  //       setFinalPrice(res?.data?.finalPrice);
  //       if (res?.data?.finalPrice <= 0) {
  //         setPaymentMethod(0);
  //       } else {
  //         setPaymentMethod(selectedPaymentMethod);
  //       }
  //       setOrderDetails(res.data);
  //       setLoader(false);
  //     });
  //   } else {
  //     setLoader(false);
  //   }
  // };

  //   const handleCheckout = async () => {
  //     setLoader(true);

  // console.log(orderId)

  //     if (window.localStorage.getItem("showDocuments") === "true") {
  //       return navigate(`/document/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`);
  //     }

  //     const payload = { orderId: newOrderId as string };

  //     if (planPrice<= 0) {
  //       setLoader(true);
  //       const payload: any = {
  //         orderId: newOrderId as string,
  //         status: 2 as number,
  //       };
  //       purchaseOrder(payload)
  //         .then((res) => {
  //           window.location.href = `${NIYO_URL}?status=true`;
  //           setLoader(false);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setLoader(false);
  //           toast.error(error?.data?.message || "Something went wrong!");
  //         });
  //       setPaymentMethodError("Please select payment method");
  //     } else if (selectedPaymentGateway == 1) {
  //       stripePaymentIntent(payload)
  //         .then((res: any) => {
  //           setPaymentDetails({
  //             clientSecret: res.data.paymentIntentSecretKey,
  //             paymentId: res.data.paymentId,
  //             setupIntent: res.data.setupIntent,
  //             currency: res.data.currency.toLowerCase(),
  //             label: "Total",
  //             amount: res.data.amount,
  //           });
  //           setLoader(false);
  //           setShow(true);
  //         })
  //         .catch((error: any) => {
  //           setLoader(false);
  //           toast.error(error?.data?.message || "Something went wrong!");
  //         });
  //     } else if (selectedPaymentGateway == 2) {
  //       razorpayAddOrder(payload).then((res) => {
  //         var options = {
  //           key: res.data.razorPayKeyId,
  //           order_id: res.data.razorPayOrderId,
  //           handler: function (response: any) {
  //             if (
  //               !response?.razorpay_order_id ||
  //               !response?.razorpay_payment_id ||
  //               !response?.razorpay_signature
  //             ) {
  //               Swal.fire(
  //                 "Your payment is currently in pending status. Please check your order history after 15 minutes for an update."
  //               );
  //             } else {
  //               window.location.href = "/payment";
  //             }
  //           },
  //           prefill: {
  //             name: data?.fullName,
  //             email: data?.email,
  //             contact: data?.phoneNumber,
  //           },
  //         };
  //         const rzp = new window.Razorpay(options);
  //         rzp.open();
  //       });
  //       setLoader(false);
  //     }
  //   };

  // const handleCheckout = async () => {
  //   setLoader(true);
  //   console.log(orderId);

  //   // Store order details in local storage before redirecting to the document page
  //   if (window.localStorage.getItem("showDocuments") === "true") {
  //     window.localStorage.setItem("pendingCheckout", JSON.stringify({
  //       newOrderId,
  //       selectedPaymentGateway
  //     }));
  //     return navigate(`/document/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`);
  //   }

  //   proceedToPayment(newOrderId, selectedPaymentGateway);
  // };
  // const proceedToPayment = (orderId:any, paymentGateway:any) => {
  //   const payload = { orderId };
  //   console.log(planPrice,"planprice")
  //   if (1 <= 0) {

  //     setLoader(true);
  //     purchaseOrder({ orderId, status: 2 })
  //       .then(() => {

  //         window.location.href = `${NIYO_URL}?status=true`;
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         toast.error(error?.data?.message || "Something went wrong!");
  //       });
  //     setPaymentMethodError("Please select payment method");
  //   } else if (paymentGateway == 1) {
  //     stripePaymentIntent(payload)
  //       .then((res) => {
  //         setPaymentDetails({
  //           clientSecret: res.data.paymentIntentSecretKey,
  //           paymentId: res.data.paymentId,
  //           setupIntent: res.data.setupIntent,
  //           currency: res.data.currency.toLowerCase(),
  //           label: "Total",
  //           amount: res.data.amount,
  //         });
  //         setLoader(false);
  //         setShow(true);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         toast.error(error?.data?.message || "Something went wrong!");
  //       });
  //   } else if (paymentGateway == 2) {
  //     razorpayAddOrder(payload).then((res) => {
  //       var options = {
  //         key: res.data.razorPayKeyId,
  //         order_id: res.data.razorPayOrderId,
  //         handler: function (response:any) {
  //           if (
  //             !response?.razorpay_order_id ||
  //             !response?.razorpay_payment_id ||
  //             !response?.razorpay_signature
  //           ) {
  //             Swal.fire("Your payment is currently in pending status. Please check your order history after 15 minutes for an update.");
  //           } else {
  //             window.location.href = "/payment";
  //           }
  //         },
  //         prefill: {
  //           name: data?.fullName,
  //           email: data?.email,
  //           contact: data?.phoneNumber,
  //         },
  //       };
  //       const rzp = new window.Razorpay(options);
  //       rzp.open();
  //     });
  //     setLoader(false);
  //   }
  // };

  // // Run this when the component loads to auto-trigger payment if returning from the document page
  // useEffect(() => {
  //   const storedDataString = window.localStorage.getItem("pendingCheckout");

  //   if (storedDataString) {
  //     try {
  //       const storedData = JSON.parse(storedDataString);

  //       if (storedData && storedData.newOrderId && storedData.selectedPaymentGateway) {
  //         // Remove stored data so it doesn't trigger on every page load
  //         window.localStorage.removeItem("pendingCheckout");

  //         // Automatically continue the payment process
  //         proceedToPayment(storedData.newOrderId, storedData.selectedPaymentGateway);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing pendingCheckout data:", error);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const checkDocumentStatus = () => {
  //     if (window.localStorage.getItem("documentsUploaded") === "true") {
  //       window.localStorage.removeItem("documentsUploaded");
  //       handleCheckout();
  //     }
  //   };

  //   // Check immediately when component mounts
  //   checkDocumentStatus();

  //   // Listen for localStorage changes
  //   window.addEventListener("storage", checkDocumentStatus);

  //   return () => {
  //     window.removeEventListener("storage", checkDocumentStatus);
  //   };
  // }, []);
  const handleCheckout = async () => {
    setLoader(true);
    const orderPayload: any = {
      bundleId: selectedBundleId,
      quantity: selectedQuantity,
    };
    if (geoCoordinates.lat && geoCoordinates.long) {
      orderPayload.lat = geoCoordinates.lat;
      orderPayload.long = geoCoordinates.long;
    }
    const localOrderId = localStorage.getItem("oderId");

    if (window.localStorage.getItem("showDocuments") === "true") {
      const response = await addOrder(orderPayload); // Make the API call
      if (response?.data?.orderId) {
        localStorage.setItem("oderId", response.data.orderId);
        setNewOrderId(response.data.orderId); // Set the orderId from the response
        if (window.localStorage.getItem("showDocuments") === "true") {
          return navigate(
            `/document/${response?.data?.orderId}/?paymentGateway=${selectedPaymentGateway}`
          );
        }
      }
    }

    const payload = { orderId: localOrderId as string };
    let geoData = null;
    let resGeo = null;
    // Fetch geolocation data
    try {
      geoData = await GetGeoLocations();
    } catch (e) {
    } finally {
      resGeo = await getDocumentStatus(geoData);
      const gType = resGeo?.data?.paymentGatewayType;
      // alert(finalPrices)
      if (order.finalPrice <= 0) {
        setLoader(true);
        const payload: any = {
          orderId: localOrderId as string,
          status: 2 as number,
        };
        purchaseOrder(payload)
          .then((res) => {
            window.location.href = `${NIYO_URL}?status=true`;
            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
        setPaymentMethodError("Please select payment method");
      } else if (gType == 1) {
        stripePaymentIntent(payload)
          .then((res: any) => {
            setPaymentDetails({
              clientSecret: res.data.paymentIntentSecretKey,
              paymentId: res.data.paymentId,
              setupIntent: res.data.setupIntent,
              currency: res.data.currency.toLowerCase(),
              label: "Total",
              amount: res.data.amount,
            });
            setLoader(false);
            setShow(true);
          })
          .catch((error: any) => {
            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
      } else if (gType == 2) {
        razorpayAddOrder(payload).then((res) => {
          var options = {
            key: res.data.razorPayKeyId,
            order_id: res.data.razorPayOrderId,
            handler: function (response: any) {
              if (
                !response?.razorpay_order_id ||
                !response?.razorpay_payment_id ||
                !response?.razorpay_signature
              ) {
                Swal.fire(
                  "Your payment is currently in pending status. Please check your order history after 15 minutes for an update."
                );
              } else {
                window.location.href = "/payment";
              }
            },
            prefill: {
              name: data?.fullName,
              email: data?.email,
              contact: data?.phoneNumber,
            },
          };

          const rzp = new window.Razorpay(options);
          rzp.open();
        });
        setLoader(false);
      }
    }
  };
  const handleCheckoutGuest = async () => {
    console.log(guestData,"taaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    const storedPhoneNumber = localStorage.getItem("phoneNumber");
    setLoader(true);
    trackEvent("Checkout Started", {
      userId: localStorage.getItem('selectedBundleId'),
      cartItems: selectedQuantity,
      totalPrice: finalPrice.toFixed(0),
    });
    const orderPayload: any = {
      bundleId: selectedPack?.bundeId ||localStorage.getItem('selectedBundleId'),
      quantity: selectedQuantity,
      url,
    };
    console.log(orderPayload,"akakakakakakaak")
    if (geoCoordinates.lat && geoCoordinates.long) {
      orderPayload.lat = geoCoordinates.lat;
      orderPayload.long = geoCoordinates.long;
    }
    const localOrderId = localStorage.getItem("oderId");
    let response;
    try {
       response = await addOrder(orderPayload); // Make the API call

    } catch (error) {
      console.log("Error....", error)
    }
    if(response)
    console.log("order api response", response)
    if (response?.data?.orderId) {
      // localStorage.setItem("oderId", response.data.orderId);
      setNewOrderId(response?.data?.orderId); // Set the orderId from the response

      // return navigate(
      //   `/document/${response?.data?.orderId}/?paymentGateway=${selectedPaymentGateway}`
      // );
    }

    const payload = { orderId: response?.data?.orderId as string };

    let geoData = null;
    let resGeo = null;
    // Fetch geolocation data
    try {
      geoData = await GetGeoLocations();
    } catch (e) {
    } finally {
      resGeo = await getDocumentStatus(geoData);
      const gType = resGeo?.data?.paymentGatewayType;

      if (finalPrices <= 0) {
        setLoader(true);
        const payload1: any = {
          orderId: payload.orderId,
          status: 2 as number,
        }
        purchaseOrder(payload1)
          .then((res) => {
            window.location.href = `${NIYO_URL}?status=true`;
            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
        setPaymentMethodError("Please select payment method");
      } else if (gType == 1) {
        stripePaymentIntent(payload)
          .then((res: any) => {
            setPaymentDetails({
              clientSecret: res.data.paymentIntentSecretKey,
              paymentId: res.data.paymentId,
              setupIntent: res.data.setupIntent,
              currency: res.data.currency.toLowerCase(),
              label: "Total",
              amount: res.data.amount,
            });
            trackEvent("Payment Successful", {});

            setLoader(false);
            setShow(true);
          })
          .catch((error: any) => {
            trackEvent("Payment Failed", {});

            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
      } else {
        razorpayAddOrder(payload).then((res) => {
          var options = {
            key: res.data.razorPayKeyId,
            order_id: res.data.razorPayOrderId,
            handler: function (response: any) {
              if (
                !response?.razorpay_order_id ||
                !response?.razorpay_payment_id ||
                !response?.razorpay_signature
              ) {
                Swal.fire(
                  "Your payment is currently in pending status. Please check your order history after 15 minutes for an update."
                );
                trackEvent("Payment failed", {});
              } else {
                trackEvent("Payment Successful", {});

                navigate(`/payment/${payload.orderId}`);
              }
            },
            prefill: {
              name: guestData?.fullName,
              email: guestData?.email,
              contact: storedPhoneNumber,
            },
            modal: {
              ondismiss: function () {
                console.log("Razorpay window dismissed by user.");
                // Reload the page if user cancels/exit
                window.location.reload();
              },}
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        });
        setLoader(false);
        localStorage.removeItem('selectedBundleId');
        localStorage.removeItem('phoneNumber');
        // window.location.reload();

      }
    }
  };
  const handleCheckout2 = async () => {
    setLoader(true);
    trackEvent("Checkout Started", {
      userId: selectedBundleId, // Assuming you have current user details
      cartItems: selectedQuantity,
      totalPrice: finalPrice.toFixed(0),
    });
    const orderPayload: any = {
      bundleId: selectedPack?.bundeId ,
      quantity: selectedQuantity,
      url,
    };
    if (geoCoordinates.lat && geoCoordinates.long) {
      orderPayload.lat = geoCoordinates.lat;
      orderPayload.long = geoCoordinates.long;
    }
    const localOrderId = localStorage.getItem("oderId");

    const response = await addOrder(orderPayload); // Make the API call
    if (response?.data?.orderId) {
      // localStorage.setItem("oderId", response.data.orderId);
      setNewOrderId(response.data.orderId); // Set the orderId from the response

      // return navigate(
      //   `/document/${response?.data?.orderId}/?paymentGateway=${selectedPaymentGateway}`
      // );
    }

    const payload = { orderId: response.data.orderId as string };

    let geoData = null;
    let resGeo = null;
    // Fetch geolocation data
    try {
      geoData = await GetGeoLocations();
    } catch (e) {
    } finally {
      resGeo = await getDocumentStatus(geoData);
      const gType = resGeo?.data?.paymentGatewayType;

      if (finalPrices <= 0) {
        setLoader(true);
        const payload1: any = {
          orderId: payload.orderId,
          status: 2 as number,
        };
        purchaseOrder(payload1)
          .then((res) => {
            window.location.href = `${NIYO_URL}?status=true`;
            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
        setPaymentMethodError("Please select payment method");
      } else if (gType == 1) {
        stripePaymentIntent(payload)
          .then((res: any) => {
            setPaymentDetails({
              clientSecret: res.data.paymentIntentSecretKey,
              paymentId: res.data.paymentId,
              setupIntent: res.data.setupIntent,
              currency: res.data.currency.toLowerCase(),
              label: "Total",
              amount: res.data.amount,
            });
            trackEvent("Payment Successful", {});

            setLoader(false);
            setShow(true);
          })
          .catch((error: any) => {
            trackEvent("Payment Failed", {});

            setLoader(false);
            toast.error(error?.data?.message || "Something went wrong!");
          });
      } else {
        razorpayAddOrder(payload).then((res) => {
          var options = {
            key: res.data.razorPayKeyId,
            order_id: res.data.razorPayOrderId,
            handler: function (response: any) {
              if (
                !response?.razorpay_order_id ||
                !response?.razorpay_payment_id ||
                !response?.razorpay_signature
              ) {
                Swal.fire(
                  "Your payment is currently in pending status. Please check your order history after 15 minutes for an update."
                );
                trackEvent("Payment Failed", {});
              } else {
                navigate(`/payment/${payload.orderId}`);
                trackEvent("Payment Successful", {});

              
              }
            },
            prefill: {
              name: data?.fullName,
              email: data?.email,
              contact: data?.phoneNumber,
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        });
        setLoader(false);
      }
    }
  };
  // useEffect(() => {
  //   if (window.localStorage.getItem("documentsUploaded") === "true") {
  //     window.localStorage.removeItem("documentsUploaded");
  //     handleCheckout();
  //   }
  // }, [window.localStorage.getItem("documentsUploaded")]);

  const handleAddPromo = ({ promo, minimumSpend }: any) => {
    setPromoError("");
    setLoader(true);
    const payload = {
      orderId: newOrderId as string,
      quantity: order?.quantity,
      bundleId: bundleId,
      promoCode:
        order?.promoApplied === false || order?.promoId !== promo?._id
          ? promo?.promoCode
          : undefined,
      lat: locations?.latitude, // Default to 0 if null
      long: locations?.longitude, // Default to 0 if null
    };
    setMinimumSpend(minimumSpend);
    // if (minimumSpend >= order?.planPrice) {
    //   setPromoError(`Please expand minimum spend amount to ${minimumSpend}`)
    //   setLoader(false)
    //   return null
    // }

    // setPromoCode(promo?.promoCode);
    toast.promise(updateOrder(payload), {
      loading: "Updating Order...",
      success: ({ data }) => {
        // setOrderDetails(data?.data);
        setLoader(false);

        if (data) {
          return "Promo applied successfully!";
        } else {
          setPromoCode("");
          return "Promo removed successfully!";
        }
      },
      error: (error) => {
        setLoader(false);
        return error?.data?.message || "Failed to update order.";
      },
    });
  };

  // const userData = async () => {
  //   dispatch(fetchDataStart());
  //   try {
  //     const userData = await getUserData();
  //     dispatch(fetchDataSuccess(userData?.data));
  //     setLoader(false);
  //   } catch (error: any) {
  //     dispatch(fetchDataFailure(error.message));
  //   }
  // };

  const handlePaymentMethod = (methodId: number) => {
    // setLoader(true)
    // userData()
    setSelectedPaymentGateway(methodId);
    setWalletAmShow(false);
    setWalletInput(false);
    setWalletMethod(0);
  };
  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    if (event.type === "change") {
      setPromoCode(event.target.value);
    } else if (event.type === "submit") {
      event.preventDefault();
      if (!isAuthenticated) {
        window.localStorage.setItem(
          "current_url",
          `/checkout/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`
        );

        return navigate("/login");
      }
      if (!promoCode) return toast.error("Please enter a promo code!");
      const payload: any = {
        quantity: order.quantity,
        orderId: newOrderId as string,
        promoCode: promoCode,
        lat: locations?.latitude, // Default to 0 if null
        long: locations?.longitude, // Default to 0 if null
      };
      toast.promise(updateOrder(payload), {
        loading: "Updating Order...",
        success: ({ data }) => {
          // setOrderDetails(data?.data);
          setLoader(false);
          return "Promo applied successfully!";
        },
        error: (error) => {
          setLoader(false);
          return (
            "Invalid promo code!" ||
            error?.data?.message ||
            "Failed to update order."
          );
        },
      });
    }
  };

  const handleBundleDetails = (params: any) => {
    setLoader(true);
    setBundleAmount(params?.dataAmount);
    setBundlePlans(params?.duration);
    const query: any = {
      dataAmount: params?.dataAmount,
      duration: params?.duration,
      search: params?.search, //params?.search filteredCountry
    };

    getbundles(query)
      .then(async (res: any) => {
        setBundleId(res?.data?.bundles[0]?._id);
        setBundleDetails(res?.data?.bundles[0]);

        if (res?.data?.bundles?.length > 0) {
          const payload = {
            orderId,
            bundleId: res?.data?.bundles[0]?._id,
            quantity: 1,
            lat: locations?.latitude, // Default to 0 if null
            long: locations?.longitude, // Default to 0 if null
          };
          updateOrder(payload).then((res) => {
            setFinalPrice(res?.data?.finalPrice);
            setOrderDetails(res?.data);
            setLoader(false);
          });
        } else {
          setLoader(false);
        }
      })
      .catch((erro) => {
        setOrderDetails([]);
      });
  };

  const handleFilterOrderUpdate = () => {
    if (bundleId) {
      setLoader(true);
      const payload = {
        orderId: newOrderId as string,
        bundleId,
        quantity: bundleQty,
        lat: locations?.latitude, // Default to 0 if null
        long: locations?.longitude, // Default to 0 if null
      };
      updateOrder(payload).then((res) => {
        setFinalPrice(res?.data?.finalPrice);
        setShowPackageOption(!showPackageOption);
        setOrderDetails(res.data);
        setLoader(false);
      });
    }
  };

  const handleOffersForyou = (id: string, offerType: number) => {
    setLoader(true);
    const payload = {
      orderId: newOrderId as string,
      offerId: id,
      offerType,
    };
    gapplyOfferForYou(payload).then((res) => {
      setLoader(false);
    });
  };
  const isAndroidOrIOS =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const handelCompatibleDevice = () => {
    if (!deviceName)
      return setDeviceNameMsg({
        error: "Please enter device name.",
        success: "",
      });
    getCompatibleDevice({ deviceName })
      .then((res) => {
        res?.statusCode === 200
          ? setDeviceNameMsg({ error: "", success: res?.data?.deviceMessage })
          : setDeviceNameMsg({
              error: res?.data?.deviceMessage || "Something went wrong!!!",
              success: "",
            });
      })
      .catch((error) => {
        setLoader(false);
        setDeviceNameMsg({ error: "Something went wrong!!!", success: "" });
      });
  };

  // useEffect(() => {
  //   if (isPending || !esimFilterData) return;

  //   const getPlans = esimFilterData?.durationData?.find(
  //     (item: any) => item.duration === bundlePlans
  //   );

  //   setBundleFilterAmount(getPlans?.package?.sort((a: any, b: any) => a - b));

  //   if (getPlans?.package?.length > 0) {
  //     if (!getPlans?.package?.includes(bundleAmount)) {
  //       setBundleAmount(getPlans?.package[0]);
  //     }

  //     const query: any = {
  //       dataAmount: getPlans?.package?.includes(bundleAmount)
  //         ? bundleAmount
  //         : getPlans?.package[0],
  //       duration: bundlePlans,
  //       search: filteredCountry,
  //       lat: locations?.latitude,
  //       long: locations?.longitude,
  //     };

  //     // Set pending flag before making API call
  //     setIsPending(true);
  //     setLoader3(true);

  //     getbundles(query)
  //       .then(async (res: any) => {
  //         setBundleId(res?.data?.bundles[0]?._id);
  //         setBundleDetails(res?.data?.bundles[0]);
  //         setDays(res?.data?.bundles[0]?.perDay);
  //         setOrderDetailss(res?.data?.bundles[0]);

  //         if (res?.data?.bundles?.length > 0) {
  //           const payload = {
  //             orderId,
  //             bundleId: res?.data?.bundles[0]?._id,
  //             quantity: 1,
  //             lat: locations?.latitude,
  //             long: locations?.longitude,
  //           };
  //           return updateOrder(payload);
  //         }
  //       })
  //       .then((res) => {
  //         if (res) {
  //           setFinalPrice(res?.data?.finalPrice);
  //           setOrderDetails(res?.data);
  //         }
  //       })
  //       .catch((error) => {
  //         setOrderDetails([]);
  //         console.error("Error fetching bundles:", error);
  //       })
  //       .finally(() => {
  //         // Reset pending flag and loader when done
  //         setIsPending(false);
  //         setLoader3(false);
  //       });
  //   }
  // }, [esimFilterData, bundlePlans, bundleAmount, bundleFilterAmount]);

  const isFirstRender = useRef(true); // Track first render

  const [prevParams, setPrevParams] = useState(params); // Store previous params

  useEffect(() => {
    // If params have changed, reload the page
    if (JSON.stringify(prevParams) !== JSON.stringify(params)) {
      setPrevParams(params); // Update stored params
      window.location.reload(); // Reload only once per param change
    }
  }, [params]); // Depend only on params

  // useEffect(() => {
  //   // Define default values if needed
  //   const qty = 1;
  //   const lat = geoCoordinates?.lat || null;
  //   const long = geoCoordinates?.long || null;
  //   const bundleId = selectedBundleId || null;

  //   preOrderApi(qty, bundleId); // lat, long,  >> if they needed then , pass in the preOrderApi() function.

  //   // Reload page when params change
  //   if (params) {
  //     window.location.reload();
  //   }
  // }, [params])
  console.log(selectedPack, "aksjaj");
  //  useEffect(() => {
  //   if (durations.length > 0 && durations[0].package.length > 0) {
  //     setSelectedPack({ duration: durations[0].duration, pkg: durations[0].package[0] });
  //   }
  // }, [durations])
  const handleProceedToCheckout = () => {
    toast.dismiss();

    // Check if the device is eSIM compatible
    if (
      !window.localStorage.getItem("setDeviceDropDown") ||
      window.localStorage.getItem("setDeviceDropDown") === "false"
    ) {
      setCompatibleError(true);
      toast.error("Please confirm your device is eSIM compatible");
      document
        .getElementById("esimcompatible")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      return; // Do not open the modal if the device is not compatible
    }
    if (isAuthenticated) {
      // Proceed to checkout logic if authenticated
      handleCheckout2();
    } else {
      // Open modal if not authenticated
      setIsOpen(true);
    }
  };
  return (
    <Layout>
      {loader && <MainLoader />}
      <div className={styles.checkoutpage}>
        <div className="container">
          <h3>Buy eSIM For {countryNam}</h3>
          {pageError ? (
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8" style={{ color: "#fff" }}>
                {pageError}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-8">
                <div className={styles.PacageOptioOuter}>
                  <div className={styles.SelectSim}>
                    <h6>SIM card validity</h6>
                    <ul>
                      {loader2 ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <li className="col-md-2" key={index}>
                            <Skeleton
                              active
                              // paragraph={{ rows: 1 }}
                              paragraph={{ rows: 2 }}
                              title={false}
                            />
                          </li>
                        ))
                      ) : (
                        <>
                      
                          {durations.map((item) =>
                            item.package.map((pkg) => (
                              <li
                                key={`${item.duration}-${pkg}`}
                                onClick={async () => {
                                  // setLoader4((prev) => new Set(prev.add(`${item.duration}-${pkg}`))); 
                                  setLoader2(true);
                                  // setTimeout(() => {
                                  //   setLoader(false);
                                  // }, 500);
                                  try {
                                    const selectedPackageId = apiResponse.bundles?.find(
                                      (b: any) => b.dataAmount === pkg && b.duration === item.duration
                                    );
                              
                                    if (selectedPackageId) {
                                      await preOrderApi(1, selectedPackageId._id); // Wait for API call to complete
                                      localStorage.setItem('selectedBundleId', selectedPackageId._id);
                                      console.log('Stored selectedBundleId:', localStorage.getItem('selectedBundleId'));
                              
                                      setSelectedPack({
                                        duration: item.duration,
                                        pkg,
                                        bundeId: selectedPackageId._id,
                                      });
                                      setSelectedQuantity(1);
                                    }
                                  } catch (error) {
                                    console.error("Error in preOrderApi:", error);
                                  } finally {
                                    setLoader4((prev) => {
                                      const updated = new Set(prev);
                                      updated.delete(`${item.duration}-${pkg}`); // Remove from loading set when finished
                                      return updated;
                                    });
                                  }
                                }}
                                className={`${selectedPack?.duration === item.duration && selectedPack?.pkg === pkg ? styles.active : styles.inactive}`}
                              >
                                {/* <span>
                                  {item?.duration > 1
                                    ? item?.duration + ` Days`
                                    : item?.duration + ` Day`}
                                </span> */}


<span>
    
            {item.duration} {item.duration > 1 ? "Days" : "Day"} /{" "}
            {pkg === null || pkg < 0
              ? "Unlimited"
              : pkg / 1000 >= 1
              ? pkg / 1000 + " GB"
              : pkg + " MB"}
     
      </span>
 
                              </li>
                            ))
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                  <div className={styles.SelectSim}>
                    {/* <h6>Data package</h6> */}
                  
                    <div className={styles.leftcheckoutable}>
                      <h6>Quantity</h6>
                      <div className={styles.scrollTable}>
                        {apiResponse ? (
                          <>
                            <div className="ouet-cart">
                              <div className="leftCrt">
                                <div className={styles.itmenImage}>
                                  <span>
                                    <img
                                      src={CheckoutIcon}
                                      alt=""
                                      width={50}
                                      className="py-2"
                                    />
                                    {/* <img src={orders?.flagImageUrl} alt="" /> */}
                                  </span>
                                  <div>
                                    <h5>
                                      {loader2 ? (
                                        <h5 style={{ width: "180px" }}>
                                          <Skeleton
                                            active
                                            paragraph={{ rows: 1 }}
                                            title={false}
                                          />{" "}
                                        </h5>
                                      ) : (
                                        countryNam || "No Country Found"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className="rightCrt">
                                {/* {!isAuthenticated && ( */}
                                {loader2 || loader3 ? (
                                  <span style={{ width: "80px" }}>
                                    {" "}
                                    <Skeleton
                                      active
                                      paragraph={{ rows: 1 }}
                                      title={false}
                                    />
                                  </span>
                                ) : (
                                  <>
                                    <span>
                                      {" "}
                                      {currencySymbol} {planPrice?.toFixed(0)}{" "}
                                      <span
                                        style={{ fontSize: "19px" }}
                                        className="ms-1"
                                      >
                                        X
                                      </span>
                                    </span>
                                  </>
                                )}

                                {/* 
                                  ))}
                                {isAuthenticated && (
                                  loader2 ? (
                                    <span style={{ width: "80px" }}>  <Skeleton active paragraph={{ rows: 1 }} title={false} /></span>
                                  ) : (
                                    <span>
                                      {order.priceSymbol}{" "}
                                      {(
                                        (order.price?.[order.priceCurrency] ?? 0.0) *
                                        (order?.price / order?.quantity || 1)
                                      ).toFixed(2)}
                                    </span>
                                  ))} */}
                                <div className={styles.pliceselect}>
                                  <div className={styles.gormGroup}>
                                    <span
                                      className={styles.decrement}
                                      onClick={decreaseQuantity}
                                    >
                                      -
                                    </span>
                                    <p className="mb-0">{selectedQuantity}</p>
                                    <span
                                      className={styles.increment}
                                      onClick={increaseQuantity}
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>

                                {loader2 || loader3 ? (
                                  <span style={{ width: "80px" }}>
                                    {" "}
                                    <Skeleton
                                      active
                                      paragraph={{ rows: 1 }}
                                      title={false}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "19px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {currencySymbol} {finalPrices.toFixed(0)}
                                  </span>
                                )}
                                {/* 
                                {isAuthenticated && (
                                  loader2 ? (
                                    <span style={{ width: "80px" }}>  <Skeleton active paragraph={{ rows: 1 }} title={false} /></span>
                                  ) : (
                                    <span>
                                      {order.priceSymbol}{" "}
                                      {(
                                        (order.currencyRates?.[order.priceCurrency] ?? 0.0) *
                                        (order?.planPrice * order?.quantity || 1)
                                      ).toFixed(2)}
                                    </span>
                                  )
                                )} */}
                              </div>
                            </div>
                            {orders?.is_enabled_description && (
                              <div className="mt-3 d-flex align-items-center">
                                <h6 className="m-0">Discription:</h6>
                                <span className="text-white ms-2">
                                  {orders?.description}
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className="text-center"
                            style={{ color: "#fff" }}
                          >
                            {loader
                              ? "Loading..."
                              : "Sorry, we couldn't find any plans matching your selection."}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.coverCountryOuter}>
                  {loader2 ? (
                    <h4>
                      <Skeleton
                        active
                        // paragraph={{ rows: 1 }}
                        paragraph={{ rows: 1 }}
                        title={false}
                      />
                    </h4>
                  ) : (
                    // <h4>
                    //   Coverage Countries (
                    //   {esimFilterData?.coverageCountries?.length})
                    // </h4>
                    <h4>Coverage Countries ({coverageCount})</h4>
                  )}
                  <div className={styles.coverageCountry}>
                    {loader2 ? (
                      <Skeleton
                        active
                        // paragraph={{ rows: 1 }}
                        paragraph={{ rows: 1 }}
                        title={false}
                      />
                    ) : (
                      <ul>
                        {flag?.map((country: any, index: any) => (
                          <li key={index}>
                            <span>
                              <img
                                src={country.flagUrl}
                                alt={`Flag of ${country.countryName}`}
                              />
                              {country.countryName}
                            </span>
                          </li>
                        ))}

                        {/* <li>
                          <span>
                            <img src={orders?.flagImageUrl} alt="" />
                            {orders?.name}
                          </span>
                        </li> */}
                      </ul>
                    )}
                  </div>
                </div>
                {/* <div className={styles.EnterDevice}>
                  <p>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onClick={(e: any) => {
                        setDeviceDropDown(e.target.checked);
                        window.localStorage.setItem(
                          "setDeviceDropDown",
                          `${e.target.checked}`
                        );
                        e.target.checked
                          ? setCompatibleError(false)
                          : setCompatibleError(true);
                      }}
                      id="flexCheckDefault"
                      checked={
                        deviceDropDown ||
                          window.localStorage.getItem("setDeviceDropDown") ==
                          "true"
                          ? true
                          : false
                      }
                    />{" "}
                    Your device is eSIM compatible and network - unlocked.{" "}
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#supportModal"
                      onClick={() => {
                        setDeviceDropDownPopup(true);
                      }}
                    >
                      {" "}
                      Learn More
                    </span>
                  </p>
                  {compatibleError && (
                    <span className="error">
                      eSIM compatible device is required
                    </span>
                  )}
                </div> */}
                {/* <div className="checoutPay">
                  {finalPrice > 0 && (
                    <div className={styles.chosepayment}>
                      <h6>Choose Payment Method</h6>
                      {paymentMethodError && selectedPaymentMethod === 0 && (
                        <span className="error">{paymentMethodError}</span>
                      )}
                      <ul>
                        {paymentGateway == PAYMENT_METHOD.STRIPEPAY && (
                          <li onClick={() => handlePaymentMethod(1)}>
                            <span>
                              <img src={CreditCardPayIcon} alt="creditCard" />
                              <div className={styles.visass}>
                                Credit / Debit Card{" "}
                                <p>Visa, Mastercard, AMEX, CUP, JCB</p>
                              </div>
                            </span>
                            <label>
                              {" "}
                              {selectedPaymentMethod === 1 && (
                                <button className={styles.selected}>
                                  Selected
                                </button>
                              )}
                            </label>
                          </li>
                        )}
                        {paymentGateway == PAYMENT_METHOD.RAZORPAY && 
                        (
                          <li onClick={() => handlePaymentMethod(2)}>
                            <span>
                              <img src={CreditCardPayIcon} alt="creditCard" />
                              <div className={styles.visass}>
                                Credit / Debit Card{" "}
                                <p>Visa, Mastercard, AMEX, CUP, JCB Razorpay</p>
                              </div>
                            </span>
                            <label>
                              {" "}
                              {selectedPaymentMethod === 2 && (
                                <button className={styles.selected}>
                                  Selected
                                </button>
                              )}
                            </label>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div> */}

                {/* <div className={styles.EnterDevice}>
                  <h1>Coming Soon</h1>
                  <div className={styles.blurContainer}>
                    <p>
                      <input
                        checked
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        onClick={(e: any) => {
                          setAirlineDropDown(e.target.checked);
                        }}
                        id="flexCheckDefault"
                      />{" "}
                      <b>Airline Membership</b>
                    </p>
                    {airlineDropDown && (
                    {true && (
                      <div className={styles.AirlineMember}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className={styles.leftAir}>
                              <label>Frequent Flyer Airline</res?.data?.bundles[0]?._id,label>
                              <div className={styles.selectCus}>
                                <span
                                // onClick={() => setAirlineSelect(!airlineSelect)}
                                >
                                  Frequent Flyer Airline
                                </span>
                                {airlineSelect && (
                                  <ul>
                                    <li className={styles.active}>
                                      Amirates Airline
                                    </li>
                                    <li>Air Mauritius Airline</li>
                                    <li>Air Mauritius Airline </li>
                                    <li>Air India Airline</li>
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={styles.leftAir}>
                              <label>Number</label>
                              <input
                                disabled
                                type="number"
                                placeholder="Enter Number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
                {offersForYou?.length > 0 && (
                  <div className={styles.offerWeProvide}>
                    <h4>Offers for you</h4>
                    {offersForYou.map((item: any, index: number) => (
                      <div className={styles.oferOuter}>
                        <div className={styles.offerHead}>
                          <span>{item?.benefit}</span>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              if (isAuthenticated) {
                                if (!order?.offerApplied)
                                  handleOffersForyou(
                                    item?._id,
                                    item?.offerType
                                  );
                              } else {
                                window.localStorage.setItem(
                                  "current_url",
                                  `/checkout/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`
                                );
                                navigate("/login");
                              }
                            }}
                          >
                            {order?.offerApplied ? "Applied" : "Apply"}
                          </a>
                        </div>
                        <h5>{item?.detailText}</h5>
                        <p>{item?.description}</p>
                        <h6>
                          Expires {moment(item?.endDate).format("MMM DD, YYYY")}
                        </h6>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <div className={styles.rightCheckout}>
                  {bundleDetails && (
                    <div className={styles.payemntSummery}>
                      <h6>Plan Details</h6>
                      {/* {loader2 ? (
                            
                            <Skeleton active paragraph={{ rows: 1 }} title={false} />
                          ) : ( */}
                      <ul>
                        <li>
                          <span className="text-white">Validity</span>{" "}
                          <label>
                            {loader2 || loader3 ? (
                              <Skeleton
                                style={{ width: "80px" }}
                                active
                                paragraph={{ rows: 1 }}
                                title={false}
                              />
                            ) : (
                              <>
                                {selectedPack?.duration}{" "}
                                {selectedPack?.duration === 1 ? "Day" : "Days"}
                              </>
                            )}
                          </label>
                        </li>
                        {days ? (
                          ""
                        ) : (
                          <li>
                            <span className="text-white">Total Data</span>{" "}
                            <label>
                              {loader2 || loader3 ? (
                                <Skeleton
                                  style={{ width: "80px" }}
                                  active
                                  paragraph={{ rows: 1 }}
                                  title={false}
                                />
                              ) : (
                                (() => {
                                  if (selectedPackage === null)
                                    return "Unlimited";

                                  const dataSize = bundlePlanss
                                    ? (selectedPackage * bundlePlanss) / 1000
                                    : selectedPackage / 1000;

                                  return dataSize < 1
                                    ? `${(dataSize * 1000)?.toFixed(0)} MB`
                                    : `${dataSize?.toFixed(0)} GB`;
                                })()
                              )}
                            </label>
                          </li>
                        )}
                        {days && (
                          <li>
                            <span className="text-white">Per day Data</span>{" "}
                            <label>
                              {loader2 || loader3 ? (
                                <Skeleton
                                  style={{ width: "80px" }}
                                  active
                                  paragraph={{ rows: 1 }}
                                  title={false}
                                />
                              ) : (
                                <>
                                  {!selectedPack?.pkg || selectedPack?.pkg === -1
                                    ? "Unlimited"
                                    : selectedPack?.pkg / 1000 < 1
                                    ? `${selectedPack?.pkg?.toFixed(0)} MB`
                                    : Number.isInteger(selectedPack?.pkg / 1000)
                                    ? `${selectedPack?.pkg / 1000} GB`
                                    : `${(selectedPack?.pkg / 1000).toFixed(
                                        1
                                      )} GB`}
                                </>
                              )}
                            </label>
                          </li>
                        )}
                      </ul>
                      {/* )} */}
                      <h6>Payment Summary</h6>
                      <ul>
                        <li>
                          <span className="text-white">Sub-total</span>{" "}
                          <label>
                            {loader2 || loader3 ? (
                              <Skeleton
                                style={{ width: "80px" }}
                                active
                                paragraph={{ rows: 1 }}
                                title={false}
                              />
                            ) : (
                              <>
                                {currencySymbol}{" "}
                                {/* {order?.planPrice && order.planPrice.toFixed(2)} */}
                                {finalPrices.toFixed(0)}
                                {/* {(
                                  (order.currencyRates?.[order.priceCurrency] ?? 0.0) *
                                  (order?.planPrice * order?.quantity || 1)
                                ).toFixed(2)} */}
                              </>
                            )}
                          </label>
                        </li>
                        {/* <li>
                                            <span>Shipping</span> <label>$Free</label>
                                        </li> */}
                        {order.discountPrice ? (
                          <li>
                            <span>Discount</span>{" "}
                            <label>
                              {orders.priceSymbol} {order.discountPrice}
                            </label>
                          </li>
                        ) : null}
                        {/* <li>
                                            <span>Tax</span> <label>$00</label>
                                        </li> */}
                        <li className={styles.Total}>
                          <span>Total</span>
                          <label>
                            {loader2 || loader3 ? (
                              <Skeleton
                                style={{ width: "80px" }}
                                active
                                paragraph={{ rows: 1 }}
                                title={false}
                              />
                            ) : (
                              <>
                                {currencySymbol}{" "}
                                {/* {order.finalPrice && order.finalPrice.toFixed(2)} */}
                                {finalPrices.toFixed(0)}
                                {/* {(
                                (order.currencyRates?.[order.priceCurrency] ?? 0.0) *
                                (order?.planPrice * order?.quantity || 1)
                              ).toFixed(2)} */}
                              </>
                            )}
                          </label>
                        </li>
                      </ul>
                      <div className={styles.applyPromo} id="esimcompatible">
                        <h5>Apply Promo Code</h5>
                        <span style={{ color: "green" }}>
                          {order?.promoApplied && "Promo code apllied"}
                        </span>
                        <form onSubmit={handleSearchSubmit}>
                          <div className={styles.formGroup}>
                            <input
                              type="text"
                              placeholder="Enter Promo Code"
                              value={promoCode}
                              onChange={handleSearchSubmit}
                              style={{ color: "#ffffff" }}
                            />
                            <button type="submit">Apply</button>
                          </div>
                        </form>
                        {/* <input type="text" placeholder="Enter Promo Code" onChange={(e) => setPromoCode(e.target.value)} />
                        <button>Apply</button> */}

                        {/* <p>
                          You can apply your discount / referral code or use
                          airmoney with your purchase
                        </p> */}
                        <div className="">
                          <p className="mb-3 text-white">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              required
                              onClick={(e: any) => {
                                setDeviceDropDown(e.target.checked);
                                window.localStorage.setItem(
                                  "setDeviceDropDown",
                                  `${e.target.checked}`
                                );
                                e.target.checked
                                  ? setCompatibleError(false)
                                  : setCompatibleError(true);
                              }}
                              id="flexCheckDefault"
                              checked={
                                deviceDropDown ||
                                window.localStorage.getItem(
                                  "setDeviceDropDown"
                                ) == "true"
                                  ? true
                                  : false
                              }
                            />{" "}
                            {/* Before completing this order please */}
                            <span id="esimcompatible">
                              {" "}
                              Your device is eSIM compatible and network -
                              unlocked.
                            </span>
                            {compatibleError && (
                              <div
                                className="error-message"
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "4px",
                                }}
                              >
                                Please confirm your device compatibility
                              </div>
                            )}
                            {/* <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#supportModal"
                              onClick={() => {
                                setDeviceDropDownPopup(true);
                              }}
                            >
                              {" "}
                              Learn More
                            </a> */}
                          </p>
                          {/* <div className="mb-3">
                            {compatibleError && (
                              <span className="error ">
                                eSIM compatible device is required
                              </span>
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                      <button
                                className="btn btn-secondary  text-dark"
                                style={{
                                  backgroundColor: "#03FE9D",
                                  border: "0",
                                  color: "dark",
                                }}
                                id="proceedToCheckout"
                                // onClick={() => {
                                //   toast.dismiss();
                                //   if (
                                //     !window.localStorage.getItem(
                                //       "setDeviceDropDown"
                                //     ) ||
                                //     window.localStorage.getItem(
                                //       "setDeviceDropDown"
                                //     ) === "false"
                                //   ) {
                                //     setCompatibleError(true);
                                //     // toast.error("Please confirm your device is eSIM compatible");
                                //     if (!deviceDropDown) {
                                //       setCompatibleError(true);
                                //       return; // Stop form submission if checkbox is not checked
                                //     }
                                //     const element =
                                //       document.getElementById("esimcompatible");
                                //     if (element) {
                                //       setTimeout(() => {
                                //         element.scrollIntoView({
                                //           behavior: "smooth",
                                //           block: "center",
                                //         });
                                //       }, 100);
                                //     }
                                //     return;
                                //   }

                                //   if (isAuthenticated) {
                                //     if (
                                //       window.localStorage.getItem(
                                //         "setDeviceDropDown"
                                //       ) === "false"
                                //     ) {
                                //       setCompatibleError(true);
                                //       toast.error(
                                //         "Please confirm your device is eSIM compatible"
                                //       );
                                //       const element =
                                //         document.getElementById("esimcompatible");
                                //       if (element) {
                                //         setTimeout(() => {
                                //           element.scrollIntoView({
                                //             behavior: "smooth",
                                //             block: "center",
                                //           });
                                //         }, 100);
                                //       }
                                //     } else {
                                //       handleCheckout2();
                                //     }
                                //   } else {
                                //     const niyoToken =
                                //       window.localStorage.getItem("niyoToken");
                                //     if (!niyoToken) {
                                //       window.localStorage.setItem(
                                //         "current_url",
                                //         `/checkout/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`
                                //       );
                                //       navigate(`/login`);
                                //     } else {
                                //       if (
                                //         window.localStorage.getItem(
                                //           "setDeviceDropDown"
                                //         ) === "false"
                                //       ) {
                                //         setCompatibleError(true);
                                //         toast.error(
                                //           "Please confirm your device is eSIM compatible"
                                //         );
                                //         document
                                //           .getElementById("esimcompatible")
                                //           ?.scrollIntoView({
                                //             behavior: "smooth",
                                //             block: "center",
                                //           });
                                //       } else {
                                //         handleCheckout2();
                                //       }
                                //     }
                                //   }
                                // }}
                                // disabled={
                                //   urlToken && secureToken?.error
                                //     ? true
                                //     : false || loader2
                                // }
                                // onClick={() => {
                                //   toast.dismiss();

                                //   if (window.innerWidth <= 768) { // Only scroll in mobile view
                                //     const element = document.getElementById("esimcompatible");

                                //     if (element) {
                                //       setTimeout(() => {
                                //         const yOffset = -100; // Adjust based on your layout
                                //         const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

                                //         // Ensure smooth scrolling
                                //         window.requestAnimationFrame(() => {
                                //           window.scrollTo({ top: y, behavior: "smooth" });
                                //         });

                                //         // Ensure focus for accessibility
                                //         element.setAttribute("tabindex", "-1");
                                //         element.focus();
                                //       }, 200);
                                //     }
                                //   }

                                //   if (
                                //     !window.localStorage.getItem("setDeviceDropDown") ||
                                //     window.localStorage.getItem("setDeviceDropDown") === "false"
                                //   ) {
                                //     setCompatibleError(true);
                                //     return;
                                //   }

                                //   if (isAuthenticated) {
                                //     handleCheckout2();
                                //   } else {
                                //     const niyoToken = window.localStorage.getItem("niyoToken");
                                //     if (!niyoToken) {
                                //       window.localStorage.setItem(
                                //         "current_url",
                                //         `/checkout/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`
                                //       );
                                //       navigate(`/login`);
                                //     } else {
                                //       handleCheckout2();
                                //     }
                                //   }
                                // }}        Proceed to Checkout{" "}
                                onClick={() => {
                                  toast.dismiss();

                                  if (window.innerWidth <= 768) {
                                    // Apply scrolling only in mobile view
                                    const errorElement =
                                      document.getElementById("esimcompatible");

                                    if (errorElement) {
                                      setTimeout(() => {
                                        const errorTop =
                                          errorElement.getBoundingClientRect()
                                            .top + window.scrollY;
                                        const viewportHeight =
                                          window.innerHeight;

                                        // Calculate scroll position to center the error message
                                        const scrollToY =
                                          errorTop -
                                          viewportHeight / 2 +
                                          errorElement.clientHeight / 2;

                                        // Smooth scrolling to center the error message
                                        window.scrollTo({
                                          top: scrollToY,
                                          behavior: "smooth",
                                        });

                                        // Ensure focus for accessibility
                                        errorElement.setAttribute(
                                          "tabindex",
                                          "-1"
                                        );
                                        errorElement.focus();
                                      }, 200);
                                    }
                                  }

                                  if (
                                    !window.localStorage.getItem(
                                      "setDeviceDropDown"
                                    ) ||
                                    window.localStorage.getItem(
                                      "setDeviceDropDown"
                                    ) === "false"
                                  ) {
                                    setCompatibleError(true);
                                    return;
                                  }

                                  if (isAuthenticated) {
                                    handleCheckout2();
                                  } else {
                                    const niyoToken =
                                      window.localStorage.getItem("niyoToken");
                                    if (!niyoToken) {
                                      window.localStorage.setItem(
                                        "current_url",
                                        `/checkout/${newOrderId}/?paymentGateway=${selectedPaymentGateway}`
                                      );
                                      openModal()
                                    } else {
                                      handleCheckout2();
                                    }
                                  }
                                }}
                              >
                              Proceed to Checkout
                                <i className="fas fa-arrow-right"></i>
                              </button>

                      </div>
                    </div>
                  )}
                  

                  <GuestLogin modalIsOpen={modalIsOpen} onClose={closeModal} />


                  <div className={styles.packageDetailsPoint}>
                    <h4>Highlights :</h4>
                    <ul className="pacjage-count">
                      <li>
                        Get an instant data connection with a 5G data-only eSIM.
                      </li>
                      <li>
                        The validity of the package will begin once the OR code
                        is scanned and the eSIM is installed.
                      </li>
                      <li>VPN: Auto APN</li>
                      <li>
                        Get connected right after you land in your desired
                        country
                      </li>
                      <li>
                        24/7 business support and customer support services
                      </li>
                      <li>Supports Android, iOS eSIM compatible devices</li>
                      <li>
                        You will receive your eSIM QR within 24 hours via email,
                      </li>
                      <li>Sent by Commbitz!</li>
                      <li>
                        <Link to="/termsConditions">Terms and Conditions </Link>
                        & <Link to="/refundPolicy">Cancelation Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {
          // selectedPaymentMethod === 2 || selectedPaymentMethod === 1 &&
          show && (
            <StripePaymentModal
              show={show}
              {...paymentDetails}
              orderId={newOrderId as string}
              closeModal={() => setShow(false)}
              paymentMethod={selectedPaymentGateway}
              siteDomain={window.localStorage.getItem("niyoToken") as string}
            />
          )
        }
      </div>

      <div
        className="modal fade show"
        id="changeplan"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showPackageOption ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowPackageOption(!showPackageOption)}
              ></button>
            </div>
            <div className="modal-body p-0 b-0">
              <div className={styles.PacageOptioOuter}>
                <h4>
                  Package options
                  <a href="#">Clear All</a>
                </h4>
                <div className={styles.SelectSim}>
                  <h6>SIM card validity</h6>
                  <ul>
                    {esimFilterData &&
                      esimFilterData?.validityDays?.map(
                        (item: number, index: number) => (
                          <li
                            className={
                              item === bundlePlans ? styles.active : ""
                            }
                            onClick={() =>
                              handleBundleDetails({
                                duration: item,
                                dataAmount: bundleAmount,
                                search: order?.name,
                              })
                            }
                          >
                            <span>
                              {item > 1 ? item + ` Days` : item + ` Day`}
                            </span>
                          </li>
                        )
                      )}
                  </ul>
                </div>
                <div className={styles.SelectSim}>
                  <h6>Data packages</h6>
                  <ul>
                    {esimFilterData &&
                      esimFilterData?.dataAmount?.map(
                        (item: number, index: number) => (
                          <li
                            className={
                              item === bundleAmount ? styles.active : ""
                            }
                            onClick={() =>
                              // handleBundleDetails({
                              //   dataAmount: item,
                              //   duration: bundlePlans,
                              //   search: order?.name,
                              // })
                              setBundleAmount(item)
                            }
                          >
                            <span>
                              {item === null
                                ? "Unlimited"
                                : item / 1000 >= 1
                                ? item / 1000 + " GB"
                                : item + " MB"}
                            </span>
                          </li>
                        )
                      )}
                  </ul>
                </div>
                <div className={styles.Quantity}>
                  <h6>Quantity</h6>
                  {bundleDetails ? (
                    <div className={styles.quaOuter}>
                      <label>
                        <img src={chip} alt="" />
                        {bundleDetails?.name}
                      </label>
                      <div className={styles.count}>
                        <h5>
                          {bundleDetails?.priceSymbol}{" "}
                          {(
                            (bundleDetails?.price
                              ? bundleDetails?.price
                              : bundleDetails?.planPrice) * bundleQty
                          )?.toFixed(0)}
                        </h5>
                        <div className={styles.inputCount}>
                          <span
                            onClick={() =>
                              bundleQty > 1 ? setBundleQty(bundleQty - 1) : 1
                            }
                          >
                            -
                          </span>
                          <input
                            type="text"
                            placeholder="0"
                            value={bundleQty}
                          />
                          <span onClick={() => setBundleQty(bundleQty + 1)}>
                            +
                          </span>
                        </div>

                        <button
                          className={styles.inputCount}
                          onClick={handleFilterOrderUpdate}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center" style={{ color: "#fff" }}>
                      Plans not found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deviceDropDownPopup && (
        <div
          className="modal fade show modal-lg"
          id="supportModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setDeviceDropDownPopup(false);
                  }}
                ></button>
              </div>
              <div className="modal-body p-0">
                <div className={styles.suportHead}>
                  <h4>Device Campatibility</h4>
                </div>
                <div className={styles.supportDesc}>
                  <h5>What device suppoprt eSIM?</h5>

                  <p>Tu Sue as commbitz, a device must meet the following:</p>
                  {deviceNameData &&
                    deviceNameData?.map((items: any, index: number) => (
                      <>
                        <h6 key={index}>
                          {items.name.charAt(0).toUpperCase() +
                            items.name.slice(1)}{" "}
                          devices that support eSIM ?
                        </h6>
                        <ul>
                          {items?.deviceName?.map(
                            (item: string, index: number) => (
                              <li key={index}>{item}</li>
                            )
                          )}
                        </ul>
                      </>
                    ))}

                  <p>
                    You can use our list to see it the device you want to use is
                    eSIM. Compatible. Note, same regional model may not support
                    eSIMs.
                  </p>
                  {/* <a href="#">READ AND ACCEPT</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Checkout;
