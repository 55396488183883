import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import rightLogo from "../../../assets/images/logo.png";
import { getGuestIP, guestLogin, reverseGeocode2 ,} from '../api'; // Assuming guestLogin is defined in API file
import { toast } from 'react-toastify';
import PhoneInput, { parsePhoneNumber, } from "react-phone-number-input";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { DEVICE_TYPE } from '../../../utils/constants';
import useAuth from '../../../lib/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { setGuestData } from '../../../redux/slices/guestLoginSlice';
// Type for the form values, including the new fields
interface FormValues {
  name: string;
  email: string;
  phone: string;
  fullName: string;
  countryCode: string;
  phoneNumber: string;
  deviceType: number;
  deviceToken: string;
  isEmailVerified: boolean;
}

interface GuestLoginProps {
  modalIsOpen: boolean;
  onClose: () => void;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
    phone: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Please enter valid phone number!")
    .required("Phone number is required")
    .min(10, "Please enter valid phone number!")
    .max(13, "Please enter valid phone number!")
    .required("Phone number is required"),
})

const GuestLogin: React.FC<GuestLoginProps> = ({ modalIsOpen, onClose }) => {
  const dispatch = useDispatch  ();
  const [loader, setLoader] = useState<boolean>(false);
  const { isAuthenticated, loginUser } = useAuth();
  const [countryCode, setCountryCode] = useState<any>(""); 
  useEffect(() => {
    async function fetchCountryCode() {
      const ip = await getGuestIP();
      console.log(ip,"akakakakatatata")
      const code = await reverseGeocode2(ip);
      console.log('code: ', code);
      
      if (code) {
        setCountryCode(code); // Just use the country code string directly
      }
    }
 
    fetchCountryCode();
  }, []);
  if (!modalIsOpen) return null;

  return (
    <>
      {/* Modal */}
      {modalIsOpen && (
        <div className="modal fade show d-block" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Guest Login</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {/* Formik Form */}
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    fullName: '',
                    countryCode: '',
                    phoneNumber: '',
                    deviceType: DEVICE_TYPE.WEB,
                    deviceToken: '',
                    isEmailVerified: true
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikHelpers) => {
                    if (!isPossiblePhoneNumber(values.phone) || !isValidPhoneNumber(values.phone)) {
                      toast.error("Invalid phone number ");
                      return;
                  }
                    const parsedPhone = parsePhoneNumber(values.phone);
                    if (!parsedPhone) {
                      toast.error("Invalid phone number ");
                      return;
                    }

                    // Correctly extracting country code and phone number
                    const countryCode = `+${parsedPhone.countryCallingCode}`;
                    const phoneNumber = parsedPhone.nationalNumber as string;
                    const initialValue = {
                      fullName: values.name,
                      email: values.email,
                      countryCode: countryCode, // ✅ Now correctly extracted
                      phoneNumber: phoneNumber,
                      deviceType: DEVICE_TYPE.WEB,
                      deviceToken: "some_device_token",
                      isEmailVerified: true,
                    };

                    guestLogin(initialValue)
                      .then(response => {
                        const { accessToken, user} = response.data;
                        const { fullName, email, phoneNumber } = user; 
                        console.log(user,"guestlogin")
                        dispatch(setGuestData({ fullName, email, phone: phoneNumber }));
                        localStorage.setItem("phoneNumber",phoneNumber);
                        loginUser(accessToken);
                        
    toast.success(response?.message || "Logged in successfully!"); // Show success message from backend
    console.log(response?.message,"response?.message")
                        onClose();
                      })
                      .catch(error => {
                        if (error && error.status === 400) {
                          // Extract and log the message from the error response
                          console.log("Error message:", error.data.message);
                          toast.error( error.data.message || error.message)
                        } else {
                          // Handle other types of errors
                          console.log("An unexpected error occurred:", error);
                          toast.error(  error)
                        }
                      });
                  }}
                >
                  {({ setFieldValue, values, touched, errors }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label text-white">Name</label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Enter your name"
                          className="form-control"
                        />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label text-white">Email</label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          className="form-control"
                        />
                        <ErrorMessage name="email" component="div" className="text-danger" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label text-white">Phone Number</label>
                        <PhoneInput
                          placeholder="Input your number here"
                          value={values.phone}
                          onChange={(value) => setFieldValue("phone", value)}
                          className="form-control "
                          defaultCountry={countryCode } 
                        />
                        {touched.phone && errors.phone && (
                          <div className="text-danger mt-1">
                            {errors.phone}
                          </div>
                        )}
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-success w-100 py-2 mt-3 text-dark" style={{background:"#03FE9D"}}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GuestLogin;
