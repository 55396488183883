import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state
interface GuestState {
  fullName: string;
  email: string;
  phone: string;
}

const initialState: GuestState = {
    fullName: "",
  email: "",
  phone: "",
};

// Create the slice
const guestLoginSlice = createSlice({
  name: "guestLogin",
  initialState,
  reducers: {
    setGuestData: (state, action: PayloadAction<GuestState>) => {
      state.fullName = action.payload.fullName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
    },
    clearGuestData: (state) => {
      state.fullName = "";
      state.email = "";
      state.phone = "";
    },
  },
});

// Export actions
export const { setGuestData, clearGuestData } = guestLoginSlice.actions;

// Export the reducer
export default guestLoginSlice.reducer;
