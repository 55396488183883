import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../slices/userSlice";
import currencySlice from "../slices/currencySlice";
import userDetailSlice from "../slices/userDetailSlice";
import orderSlice from "../slices/orderSlice"; 
import locationReducer from "../slices/locationSlice";
import guestLoginSlice from "../slices/guestLoginSlice";
export const store = configureStore({
  reducer: {
    getUserData: userSlice.reducer,
    getCurrency: currencySlice.reducer,
    getUserDetail: userDetailSlice.reducer,
    order: orderSlice,
    location: locationReducer,
    guestLogin: guestLoginSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
