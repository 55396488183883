import { API_URL } from '../../../../config';
import { axios } from '../../../../lib/axios';
import { SignupPayload, SignupResponse, SendOtpResponse, sendOtpPayload, VeryOtpResponse, verifyOtpPayload } from '../interface';

const signup = async (values: SignupPayload): Promise<SignupResponse> => {
    return axios.post(`${API_URL}/auth/signup`, values);
}

const sendOtp = async (values: sendOtpPayload): Promise<SendOtpResponse> => {
    return axios.post(`${API_URL}/otp/send`, values);
}

const verifyOtp = async (values: verifyOtpPayload): Promise<VeryOtpResponse> => {
    return axios.post(`${API_URL}/otp/verify`, values);
}

const uploadImage = async (values:any) => {
    const headers:any = {
        'Content-Type': 'multipart/form-data',
      };
    return axios.post(`${API_URL}/common/upload`, values, headers);
}
export async function reverseGeocode2Signup(ip: any): Promise<any> {
    const url = `https://api.ipinfo.io/lite/${ip}?token=ed0651b68a4f39`;
    
    try {
    console.log("Checking URL:", url);
    const response = await fetch(url);
    
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();
    // Ensure the country_code exists and return it, otherwise return an empty string
    return data && data.country_code ? data.country_code.toUpperCase() : "";
    } catch (error) {
    console.error("Error during reverse geocoding:", error);
    return "";
    }
}
const getGuestIPSignup = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        console.log(response,"akshaythakur")
        const data = await response.json();
        console.log(data,"akshaythakur")
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP:', error);
        return '';
    }
}

export {getGuestIPSignup,
    signup,
    sendOtp,
    verifyOtp,
    uploadImage
};