declare const clevertap: any; // Use the globally available CleverTap object

export const trackEvent = (eventName: string, eventData: object = {}) => {
    if (typeof clevertap !== "undefined") {
        clevertap.event.push(eventName, eventData);
        console.log(`Tracked event: ${eventName}`, eventData);
    } else {
        console.warn("CleverTap is not initialized yet.");
    }
};
