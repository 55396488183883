import styles from '../styles/style.module.css';
import Layout from "../../../components/layout"
import sucess from "../../../assets/images/sucess.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumbs';
import { useEffect } from 'react';

const Payment = () => {
    const navigate = useNavigate();
    const { orderId } = useParams(); 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleNavigation = () => {
        navigate(`/document/${orderId}`);
    };
    return (
        <Layout>
            <Breadcrumb />
            <div className={styles.paymentSucess}>
                <div className='container'>
                    <div className={styles.paymentSucessInner}>
                        <img src={sucess} alt="" />
                        <h4>Payment Successful!</h4>
                        <p>Thank you for making a purchase! We will notify you via email. Thank you for your patience!</p>
                        <div className={styles.SuccBtn}>
                        <button onClick={handleNavigation}>Upload Document</button>
                        <Link to="/order-histories">View Order</Link>
                            <Link to="/">Go to homepage</Link>
                          
                           
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Payment;