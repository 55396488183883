import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import helpmob from "../../../assets/images/helpmob.svg";
import srchic from "../../../assets/images/srchic.svg";
import { BundleCard } from "../../../components";
import MainLoader from "../../../components/mainLoader";
import Pagination from "../../../components/pagination";
import { GetGeoLocations } from "../../../utils/GetGeoLocation";
import { BUNDLE_TYPE } from "../../../utils/constants";
import { addOrder, getDocumentStatus } from "../../checkout/api";
import { Bundle } from "../../esim/interface";
import { getCompatibleDevice } from "../../packageOption/api";
import { getCountries, getRegions, getbundles } from "../api";
import styles from "../styles/home.module.css";
import { STRINGS } from "../../../constants/ strings";
import installBk from "../../../assets/images/installbk.png";
import mobheader from "../../../assets/images/mobheader.png"
interface Coordinates {
  latitude: number;
  longitude: number;
  name: string;
}

const EsimDestination: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eSimPage = location.pathname.split("/").includes("esim-list");

  const { latitude, longitude } = useSelector((state: any) => state.location);

  const [activeTab, setActiveTab] = useState<number>(BUNDLE_TYPE.LOCAL);

  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [getDatas, setGetDatas] = useState<any>();
  const [getMobDatas, setGetMobDatas] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const currency = useSelector((state: any) => state?.getCurrency?.currency);
  const [welcomeModalShow, setWelcomeModalShow] = useState(false);
  const [country, setCountry] = useState<string>("India");
  const loadmore: boolean = true;
  const [searchVal, setSearchVal] = useState<any>({
    search: "",
    submit: false,
  });

  const [countriesData, setCountriesData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [geoLocationData, setGeoLocationData] = useState<any>({
    latitude: 0,
    longitude: 0,
  });
  const [paymentGateway, setPaymentGateway] = useState<any>(null);
  const [geoLocationErr, setGeoLocationErr] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSomeDropdown, setShowSomeDropdown] = useState<any>({
    installation: false,
    deviceCompatibility: false,
  });
  const [deviceName, setDeviceName] = useState("");

  const [getLoader, setGetLoader] = useState<boolean>(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (isMobile) setActiveTab(1);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  const showWelcomeModalShow = (val: any) => {
    setWelcomeModalShow(val);
  };

  const pageRecords = eSimPage ? 132 : 12;

  useEffect(() => {
    let currentTime: any = new Date();

    const hasPopupBeenShown = localStorage.getItem("showWelcomeModal");

    if (!hasPopupBeenShown || currentTime.getTime() > hasPopupBeenShown) {
      currentTime.setHours(currentTime.getHours() + 12);

      localStorage.setItem("showWelcomeModal", currentTime.getTime());

      showWelcomeModalShow("show");
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    if (activeTab === BUNDLE_TYPE.POPULAR) {
      GetGeoLocations()
        .then((data: any) => {
          setGeoLocationErr(false);
          setGeoLocationData(data);

          const query: any = {
            page: currentPage,
            limit: pageRecords,
          };

          if (data.latitude || data.longitude) {
            query.lat = data?.latitude;
            query.long = data?.longitude;
          }

          if (searchVal.search) query.searchString = searchVal?.search;

          getCountries(query)
            .then((res: any) => {
              setLoader(false);
              if (currentPage === 1) {
                const data = {
                  ...res?.data,
                  countries1: res?.data?.countries,
                };
                setGetDatas(data);
              } else {
                setGetDatas((pre: any) => ({
                  ...pre,
                  countries: [...pre.countries, ...res?.data?.countries],
                }));
              }
            })
            .catch((error) => {
              console.log(error);
              setLoader(false);
            });
        })
        .catch((error) => {
          const query: any = {
            page: currentPage,
            limit: pageRecords,
            isPopular: true,
          };
          if (searchVal.search) query.searchString = searchVal?.search;

          getCountries(query)
            .then((res: any) => {
              setLoader(false);
              if (currentPage === 1) {
                setGetDatas(res?.data);
              } else {
                setGetDatas((pre: any) => ({
                  ...pre,
                  countries: [...pre.countries, ...res?.data?.countries],
                }));
              }
            })
            .catch((error) => {
              console.log(error);
              setLoader(false);
            });
          // setGeoLocationErr(true);
          // setGetDatas([]);
          // console.log("Error getting location:", error);
          // setLoader(false);
        });
    } else if (activeTab === BUNDLE_TYPE.LOCAL) {
      const query: any = {
        page: currentPage,
        limit: pageRecords,
      };

      if (searchVal.search) query.searchString = searchVal?.search;

      getCountries(query)
        .then((res: any) => {
          setLoader(false);
          if (currentPage === 1) {
            const data = {
              ...res?.data,
              countries1: res?.data?.countries,
            };

            setGetDatas(data);
            // setGetDatas(res?.data);
          } else {
            setGetDatas((pre: any) => ({
              ...pre,
              countries: [...pre.countries, ...res?.data?.countries],
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } else if (activeTab === BUNDLE_TYPE.MULTIVERSE) {
      const query: any = {
        page: currentPage,
        limit: pageRecords,
      };
      if (searchVal.search) query.search = searchVal?.search;
      getRegions(query)
        .then((res: any) => {
          setLoader(false);
          setGetDatas(res?.data);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  }, [activeTab, currency, currentPage, searchVal.submit]);

  const handleSearchSubmit = () => {
    setGeoLocationData({ latitude: 0, longitude: 0 });
    setCurrentPage(1);
    setSearchVal({
      ...searchVal,
      submit: !searchVal.submit,
    });
    setCountriesData("");
  };
  const countryAliases: Record<string, string> = {
    uk: "United Kingdom",
    usa: "United States",
    us: "United States",
    uae: "United Arab Emirates",
    dubai: "United Arab Emirates",
  };

  const handleSearchCountries = async (e: any) => {
    setLoader(true);
    const searchValue = e.target.value.toLowerCase();
    const trimmedSearch = searchValue.trim();

    // Check for alias match
    const aliasMatch = countryAliases[trimmedSearch];
    const searchTerm = aliasMatch || searchValue;

    setSearchVal({
      submit: isMobile ? !searchVal.submit : false,
      search: searchValue, // Keep original input value for display
    });
 // Handle empty input
//  if (!searchValue.trim()) {
//   setCountriesData([]);
//   setSearchVal({ submit: false, search: "" }); // Reset search value
//   setLoader(false);
//   return;
// }
    // Don't return early for alias matches
    if (!aliasMatch && searchValue.length <= 2) {
      setCountriesData([]);
      setLoader(false);
      return;
    }

    if (!searchValue) {
      setCountriesData([]);
      setLoader(false);
      return;
    }

    if (searchValue && !isMobile) {
      // Use the aliasMatch if available, otherwise use the trimmed search value
      getCountries({ searchString: aliasMatch || searchValue.trim() })
        .then((res: any) => {
          setCountriesData(res?.data?.countries);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error, "search");
          setLoader(false);
        });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handelCompatibleDevice = () => {
    if (!deviceName) return toast.error("Please enter device name");
    getCompatibleDevice({ deviceName })
      .then((res) => {
        res?.statusCode === 200
          ? toast.success(res?.data?.deviceMessage)
          : toast.error("Something went wrong!!!");
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong!!!");
      });
  };

  // const createOrder = (countryName: string) => {
  //   setGetLoader(true);

  //   let paymentGatewayType: string | undefined; // Declare outside to access later

  //   GetGeoLocations()
  //     .then((geoData: any) => {
  //       return getDocumentStatus(geoData).then((resGeo: any) => {
  //         paymentGatewayType = resGeo?.data?.paymentGatewayType; // Store value for later use
  //         setPaymentGateway(paymentGatewayType);

  //         const query: any = {
  //           search: countryName,
  //           ...(geoData?.latitude && geoData?.longitude && {
  //             lat: geoData.latitude,
  //             long: geoData.longitude,
  //           }),
  //         };

  //         return getbundles(query).then((res: any) => {
  //           const orderPayload: any = {
  //             bundleId: res?.data?.bundles[0]?._id,
  //             quantity: 1,
  //             ...(geoData?.latitude && geoData?.longitude && {
  //               lat: geoData.latitude,
  //               long: geoData.longitude,
  //             }),
  //           };

  //           window.localStorage.setItem("countryName", countryName);
  //           window.localStorage.setItem("showDocuments", resGeo?.data?.showDocuments);

  //           return addOrder(orderPayload);
  //         });
  //       });
  //     })
  //     .then((Response: any) => {
  //       navigate(`/checkout/${Response?.data?.orderId}/?paymentGateway=${paymentGatewayType}`);
  //       setGetLoader(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setGetLoader(false);
  //       // Swal.fire("Please enable your location service!");
  //       getDocumentStatus().then((resGeo: any) => {
  //         paymentGatewayType = resGeo?.data?.paymentGatewayType; // Store value for later use
  //         setPaymentGateway(paymentGatewayType);

  //         const query: any = {
  //           search: countryName,

  //         };

  //         return getbundles(query).then((res: any) => {
  //           const orderPayload: any = {
  //             bundleId: res?.data?.bundles[0]?._id,
  //             quantity: 1,

  //           };

  //           window.localStorage.setItem("countryName", countryName);
  //           window.localStorage.setItem("showDocuments", resGeo?.data?.showDocuments);

  //           return addOrder(orderPayload);
  //         });
  //       }).then((Response: any) => {
  //         navigate(`/checkout/${Response?.data?.orderId}/?paymentGateway=${paymentGatewayType}`);
  //         setGetLoader(false);
  //       });
  //     });
  // };
  // const createOrder = (countryName: string) => {
  //   setGetLoader(true);

  //   let paymentGatewayType: string | undefined; // Declare outside to access later

  //   GetGeoLocations()
  //     .then((geoData: any) => {
  //       return getDocumentStatus(geoData).then((resGeo: any) => {
  //         paymentGatewayType = resGeo?.data?.paymentGatewayType; // Store value for later use
  //         setPaymentGateway(paymentGatewayType);

  //         const query: any = {
  //           search: countryName,
  //           ...(geoData?.latitude && geoData?.longitude && {
  //             lat: geoData.latitude,
  //             long: geoData.longitude,
  //           }),
  //         };

  //         return getbundles(query).then((res: any) => {
  //           window.localStorage.setItem("countryName", countryName);
  //           window.localStorage.setItem("showDocuments", resGeo?.data?.showDocuments);

  //           return res;
  //         });
  //       });
  //     })
  //     .then(() => {
  //       navigate(`/${encodeURIComponent(countryName)}`);
  //       setGetLoader(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setGetLoader(false);

  //       getDocumentStatus().then((resGeo: any) => {
  //         paymentGatewayType = resGeo?.data?.paymentGatewayType; // Store value for later use
  //         setPaymentGateway(paymentGatewayType);

  //         const query: any = { search: countryName };

  //         return getbundles(query).then((res: any) => {
  //           window.localStorage.setItem("countryName", countryName);
  //           window.localStorage.setItem("showDocuments", resGeo?.data?.showDocuments);

  //           return res;
  //         });
  //       }).then(() => {
  //         navigate(`/${encodeURIComponent(countryName)}`);;
  //         setGetLoader(false);
  //       });
  //     });
  // };
  const createOrder = (countryName: string) => {
    setGetLoader(true);

    let paymentGatewayType: string | undefined; // Declare outside to access later

    // Retrieve lat & long from localStorage

    // this is commented bcz we are getting latitude and logitude from the store _

    // const latitude = localStorage.getItem("latitude");
    // const longitude = localStorage.getItem("longitude");

    const geoData = {
      latitude: latitude ? parseFloat(latitude) : null,
      longitude: longitude ? parseFloat(longitude) : null,
    };

    getDocumentStatus(geoData)
      .then((resGeo: any) => {
        paymentGatewayType = resGeo?.data?.paymentGatewayType;
        setPaymentGateway(paymentGatewayType);

        const query: any = {
          search: countryName,
          ...(geoData.latitude &&
            geoData.longitude && {
              lat: geoData.latitude,
              long: geoData.longitude,
            }),
        };

        return getbundles(query).then((res: any) => {
          localStorage.setItem("countryName", countryName);
          localStorage.setItem("showDocuments", resGeo?.data?.showDocuments);

          return res;
        });
      })
      .then(() => {
        navigate(`/${encodeURIComponent(countryName)}`);
        setGetLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setGetLoader(false);

        getDocumentStatus()
          .then((resGeo: any) => {
            paymentGatewayType = resGeo?.data?.paymentGatewayType;
            setPaymentGateway(paymentGatewayType);

            const query: any = { search: countryName };

            return getbundles(query).then((res: any) => {
              localStorage.setItem("countryName", countryName);
              localStorage.setItem(
                "showDocuments",
                resGeo?.data?.showDocuments
              );

              return res;
            });
          })
          .then(() => {
            navigate(`/${encodeURIComponent(countryName)}`);
            setGetLoader(false);
          });
      });
  };

  return (
    <>
      <section className={styles.esimDestination}>
        {getLoader && <MainLoader />}
        <div className="mobBk" style={
    isMobile
      ? { 
        backgroundImage: `url(${mobheader})`
        }
      : undefined
  }>

          <div className="container ipdad-cutm">
            <h6>{STRINGS.CONNECT_ANYWHERE}</h6>
            <h3>{STRINGS.INTERNATIONAL_ESIM_DESTINATIONS}</h3>
            <div className={styles.SearchBoxOuter}>
              <div className={styles.SimpleSearch}>
                <span>
                  <img width="20" height="20" src={srchic} alt="" />
                </span>
                <input
                  type="text"
                  placeholder={STRINGS.SEARCH_DESTINATION_PLACEHOLDER}
                  onChange={handleSearchCountries}
                  value={searchVal?.search}
                />
                <button >
                  {STRINGS.SEARCH_BUTTON}
                </button>
              </div>
            </div>

            {!isMobile &&
              searchVal?.search &&
              countriesData &&
              (countriesData?.length > 0 ? (
                <ul className={styles.ContrySearch}>
                  {countriesData?.map((country: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        // setSearchVal({
                        //   ...searchVal,
                        //   search: country?.name,
                        // });
                        // setCountriesData("");
                        createOrder(country?.name);
                      }}
                    >
                      <img
                        src={country?.flagImageUrl}
                        alt={country?.name}
                        style={{ width: "30px" }}
                      />
                      {country?.name}
                    </li>
                  ))}
                </ul>
              ) : (
                searchVal?.search?.length > 2 && (
                  <ul className={styles.ContrySearch}>
                    <li>
                      {" "}
                      {loader ? (
                        <Spinner animation="grow" variant="light" />
                      ) : (
                        <li>{STRINGS.COUNTRY_NOT_FOUND}</li>
                      )}
                    </li>
                  </ul>
                )
              ))}
          </div>
        </div>
      
          <div className={styles.esimBlogs}>
            <ul>
              {/* <li
                onClick={() => {
                  if (activeTab !== BUNDLE_TYPE.POPULAR) {
                    setActiveTab(BUNDLE_TYPE.POPULAR);
                    setLoader(false);
                    setSearchVal({
                      search: "",
                      submit: false,
                    });
                    setCurrentPage(1);
                  }
                }}
                className={
                  activeTab === BUNDLE_TYPE.POPULAR ? styles.active : ""
                }
              >
                <span>Popular Plans </span>
              </li> */}

              <li
                onClick={() => {
                  if (activeTab !== BUNDLE_TYPE.LOCAL) {
                    setActiveTab(BUNDLE_TYPE.LOCAL);
                    setLoader(false);
                    setSearchVal({
                      search: "",
                      submit: false,
                    });
                    setCurrentPage(1);
                  }
                }}
                className={activeTab === BUNDLE_TYPE.LOCAL ? styles.active : ""}
              >
                <span>{STRINGS.LOCAL_ESIMS}</span>
              </li>
              <li
                onClick={() => {
                  if (activeTab !== BUNDLE_TYPE.MULTIVERSE) {
                    setActiveTab(BUNDLE_TYPE.MULTIVERSE);
                    setLoader(false);
                    setSearchVal({
                      search: "",
                      submit: false,
                    });
                    setCurrentPage(1);
                  }
                }}
                className={
                  activeTab === BUNDLE_TYPE.MULTIVERSE ? styles.active : ""
                }
              >
                <span>{STRINGS.REGIONS}</span>
              </li>
            </ul>
          </div>
      

        <div className="container ipdad-cutm mobile-sims">
          <div className={styles.esimPack}>
            {(activeTab === BUNDLE_TYPE.POPULAR ||
              activeTab === BUNDLE_TYPE.LOCAL) && (
              <>
                <div className="mobHeadings">
                  {/* <h5>
                      Popular Countries
                    </h5> */}
                  {/* <a href="#">View All</a> */}
                </div>
                
                  {/* __ here added loader only other code/functionality is as it is ___ arch*/}

                <div className="row">
                    {loader ? ( 
                    <div className="col-md-12 text-center">
      {isMobile ? (
        // Show skeletons in mobile view when loading
        Array.from({ length: 12 }).map((_, index) => (
          <div className="col-md-2" key={index}>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </div>
        ))
      ) : (
        // Desktop view can remain empty or show a different loader
        <MainLoader/>
      )}
    </div>
                  ) :
                      false ? (
                      Array.from({ length: 12 }).map((_, index) => (
                        <div className="col-md-2" key={index}>
                          <Skeleton active avatar paragraph={{ rows: 2 }} />
                        </div>
                      ))
                    ) : getDatas?.countries1?.length > 0 ? (
                      // Render actual data once loaded
                      getDatas.countries1.map((item: any, index: number) => (
                        <div
                          className="col-md-2"
                          key={index}
                          onClick={() => createOrder(item?.name)}
                        >
                          <div className={styles.franceBox}>
                            {item?.flagImageUrl && (
                              <img
                                className={styles.flagfrnce}
                                width="40"
                                height="40"
                                src={item?.flagImageUrl}
                                alt=""
                                 
                              />
                            )}
                            <h4>{item?.name}</h4>
                            {item?.historicImageUrl && (
                              <img
                                className={styles.taj}
                                width="105"
                                height="104"
                                src={item?.historicImageUrl}
                                alt=""
                                 
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-md-12" style={{ color: "#fff" }}>
                      <h4 style={{ textAlign: "center" }}>
                        {STRINGS.COUNTRY_NOT_FOUND}
                      </h4>
                      
                    </div>
                      // // Show message if no data is found
                      // Array.from({ length: 12 }).map((_, index) => (
                      //   <div className="col-md-2" key={index}>
                      //     <Skeleton active avatar paragraph={{ rows: 2 }} />
                      //   </div>
                      // ))
                    )}
                    <Pagination
                      count={getDatas?.count} // Replace with the actual count from the API
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      itemsPerPage={pageRecords}
                      loadMore={loadmore}
                    />
                  </div>
                
              </>
            )}
            {activeTab === BUNDLE_TYPE.MULTIVERSE && getDatas && (
              <>
                <div className="mobHeadings">
                  <h5>
                    {STRINGS.POPULAR_REGIONS}
                    {/* <a href="#">View All</a> */}
                  </h5>
                </div>
                <div className="row">
                  {getDatas?.regions?.length > 0 ? (
                    getDatas?.regions?.map((item: any, index: number) => (
                      <div
                        className="col-md-2"
                        key={index}
                        onClick={() => createOrder(item?.name)}
                      >
                        <div className={styles.franceBoxcount}>
                          <h4>{item?.name}</h4>
                          <img
                            className={styles.taj}
                            width="90"
                            height="90"
                            src={item?.flagImageUrl}
                            alt={item?.name}
                            style={{ width: "150px" }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12" style={{ color: "#fff" }}>
                      <h4 style={{ textAlign: "center" }}>
                        {loader && (
                          <Skeleton active avatar paragraph={{ rows: 2 }} />
                        )}
                      </h4>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {isMobile ? (
            <>
              <div className="mobilePurchase" style={
    isMobile
      ? { 
          backgroundImage: `url(${installBk})`
        }
      : undefined
  }>
                <div className="leftMobPur">
                  <h5>{STRINGS.INSTALL_PURCHASED_ESIM}</h5>
                </div>
                <div className="rightmobpur">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSomeDropdown({
                        installation: !showSomeDropdown?.installation,
                        deviceCompatibility:
                          showSomeDropdown?.deviceCompatibility &&
                          !showSomeDropdown?.deviceCompatibility,
                      });
                    }}
                  >
                    {STRINGS.INSTALL_GUIDELINES}
                    <i className="fas fa-angle-double-right"></i>
                  </a>
                  {showSomeDropdown?.installation && (
                    <ul>
                      <li>
                        <a
                          href="https://www.youtube.com/shorts/voW6qD5mQKE"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>{" "}
                          {STRINGS.GUIDELINES_FOR_ANDROID}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/shorts/yJk3lSCnF2Y"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>{" "}
                          {STRINGS.GUIDELINES_FOR_IOS}
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="helpdeskMoob">
                <div className="leftdeskmob">
                  <h5> {STRINGS.GO_TO_HELP_DESK}</h5>
                  <h6>{STRINGS.HELP_DESK_SUBTITLE}</h6>
                  <Link to="/contact" target="_blank" rel="noopener noreferrer">{STRINGS.GET_HELP}</Link>
                </div>
                <div className="rightdeskmob">
                  <img src={helpmob} alt="" />
                </div>
              </div>
              <div className="deviceSuport">
                <h5>{STRINGS.CHECK_DEVICE_COMPATIBILITY}</h5>
                <h6>{STRINGS.ENSUR_YOUR_DEVICE}</h6>
                <div className="formGroup">
                  <input
                    type="text"
                    placeholder="Enter your Phone Model"
                    onChange={(e) => {
                      setDeviceName(e.target.value);
                    }}
                    style={{ color: "#fff" }}
                    value={deviceName}
                  />
                  <button onClick={handelCompatibleDevice}>
                    {STRINGS.ESIM_APPLY}
                  </button>
                </div>
              </div>
            </>
          ) : (
            false
          )}
          {isMobile
            ? getMobDatas && (
                <div className="mobileSims">
                  <h4>{STRINGS.POPULAR_PLAN_REGION}</h4>
                  <div className={styles.esimPackMob}>
                    <div className="row">
                      {/* <SearchBar searchClass={styles.topSearch} placeHolder={"Search Country, Regions, eSIM"} handleSearchSubmit={handleSearchSubmit} viewAllBundle={viewAllBundle} /> */}
                      {getMobDatas?.bundles?.length > 0 ? (
                        getMobDatas?.bundles.map(
                          (bundle: any, index: number) => (
                            <div className="col-md-3" key={index}>
                              <div className={styles.esimpackLeft}>
                                <div className={styles.PackList}>
                                  <div className="homepckCard">
                                    <BundleCard bundle={bundle} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <span className="text-center" style={{ color: "#fff" }}>
                          {STRINGS.ESIM_PLANE_NOT_FOUND}
                        </span>
                      )}
                      <Pagination
                        count={getMobDatas?.count} // You can replace this with the actual count received from the API
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                        itemsPerPage={pageRecords}
                        loadMore={loadmore}
                        loader={loader}
                      />
                    </div>
                  </div>
                </div>
              )
            : false}
        </div>

        {/* {welcomeModalShow && (
          <WelcomeBanner
            show={welcomeModalShow}
            handleCloseModal={showWelcomeModalShow}
          />
        )} */}
      </section>
    </>
  );
};

export default EsimDestination;
